<template>
  <v-container
    fluid
    class="pa-0 pr-4"
  >
    <v-row
      class="karte"
      justify="center"
    >
      <!-- カルテ情報 左カラム -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0"
      >
        <v-textarea
          v-model="karte.complaint"
          outlined
          name="complaint"
          required
          label="主訴（必須）*"
          rows="2"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />
        <v-textarea
          v-model="karte.interview"
          outlined
          name="interview"
          label="追加問診など"
          rows="5"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />
        <v-textarea
          v-if="this.karte.diagnosis"
          v-model="karte.diagnosis"
          outlined
          name="diagnosis"
          required
          label="旧 暫定診断"
          rows="2"
          :readonly="true"
          :disabled="true"
          class="pt-1 pb-0"
        />
        <midwife-diagnosis
          v-if="this.reservation.department_type === 3"
          v-model="this.karte"
          :active="active"
        />
        <diagnosis
          v-else
          v-model="this.karte"
          :active="active"
          :department-data="String(this.reservation.department_type)"
        />
        <v-textarea
          v-model="karte.guidance"
          outlined
          name="guidance"
          required
          label="指導内容（必須）*"
          rows="5"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />
        <v-select
          v-model="karte.outcome_id"
          outlined
          name="outcome"
          :items="outcomeOptions"
          item-text="key"
          item-value="value"
          required
          :readonly="!active"
          :disabled="!active"
          label="転帰（必須）*"
        />
        <v-select
          v-model="karte.tool_id"
          outlined
          name="tool"
          :items="toolOptions"
          item-text="key"
          item-value="value"
          required
          :disabled="!active"
          label="実際のツール（必須）*"
        />
        <v-textarea
          v-model="karte.message"
          outlined
          name="message"
          hint="※相談員全員に周知したいことは特記事項へ記入をお願いします。<br>※「申し送り」は次の担当者のみに伝えたいことを記載してください。"
          label="次の人への申し送り（既往歴などは特記事項へ）"
          rows="2"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />
        <v-checkbox
          v-model="karte.is_connected"
          color="pink"
          :disabled="!active"
          :class="!active && karte.is_connected ? 'disable-connected' : ''"
          label="時間内相談なし"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- カルテ情報 相談内容報告欄 -->
      <v-col
        v-if="karte.cooperate_with_clinic"
        md="12"
        class="cooperated-area"
      >
        <v-card
          flat
          ripple
        >
          <v-card-text>
            <div class="strong text-center">
              相談内容報告欄
            </div>
          </v-card-text>
          <v-card-text>
            <div>
              この相談はかかりつけクリニックに報告する必要があります。</br>
              事前問診のボックス内（氏名・生年月日など）と以下の3つの欄に入力の内容をクリニックの医師が閲覧します。</br>
              相談内容報告欄の記載をお願いします。
            </div>
          </v-card-text>
        </v-card>
        <v-btn
          :disabled="!active"
          color="pink lighten-1"
          @click="copyFromKarteFields()"
        >
          カルテ内容を反映する
          <v-icon right>
            file_copy
          </v-icon>
        </v-btn>
        <v-textarea
          v-model="karte.complaint_for_reporting"
          outlined
          name="complaint"
          required
          label="報告用主訴（必須）*"
          rows="3"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />

        <v-textarea
          v-model="karte.guidance_for_reporting"
          outlined
          name="complaint"
          required
          label="報告用指導内容（必須）*"
          rows="5"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />

        <v-textarea
          v-model="karte.outcome_for_reporting"
          outlined
          name="complaint"
          required
          label="報告用転帰（必須）*"
          rows="2"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0"
        />
      </v-col>
      <!--// カルテ情報 相談内容報告欄 -->
    </v-row>
  </v-container>
</template>

<style lang="scss">
.karte{
  .cooperated-area {
    border: 2px solid rgb(233, 30, 99);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: rgb(253, 238, 243);
  }

  .cooperated-area div.v-card {
    background: transparent;
  }
  .cooperated-area  .theme--light.v-text-field--outlined>.v-input__control>.v-input__slot {
    background-color: white!important;
  }
}

/* カルテ情報 override */
/* テキストエリア/セレクトボックス ラベル色 */
.karte div.v-input--is-disabled.v-text-field .v-text-field__slot label.v-label--is-disabled {
  color: #C0C0C0 !important;
}
/* テキストエリア/セレクトボックス 枠 */
.karte .v-text-field--outlined:not(.v-input--is-focused) fieldset,
.karte .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover fieldset {
  border: 2px solid #C0C0C0 !important;
}
/* 保存された入力文字 */
.karte div.v-input--is-disabled.v-text-field .v-text-field__slot textarea,
.karte div.v-input--is-disabled.v-text-field .v-select__selection--disabled {
  color: #212121 !important;
}
/* 時間内連絡なしチェックONの場合のみ適用されるラベル色 */
.disable-connected label.v-label.theme--light {
  color: #212121 !important;
}
/* 編集エリアの背景色 */
.karte div.v-input.v-text-field:not(.v-input--is-disabled) .v-input__slot {
  background-color: #E8F2FF !important;
}
/** チャット入力欄の背景色が上記スタイルに影響されるため強制的に白に */
#chat-input div.v-input.v-text-field:not(.v-input--is-disabled) .v-input__slot {
  background-color: #FFF !important;
}
/** 編集不可時の画像アップロードボタンの背景色 */
.karte .upload.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #CCC !important;
}

/* Upgrade対応 vietify1.5と見た目が変わらないよう marginを変更 */
.karte .v-textarea.v-text-field--outlined textarea {
  margin-top: 24px !important;
  /* outlineの枠線に重なるため */
  margin-right: 2px;
  margin-bottom: 2px;
}
.karte .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{
  padding-top: 24px;
}
.karte .v-input__slot legend {
  width: 0px !important;
}
.karte .v-text-field--outlined .v-label--active {
  max-width: 133%;
  -webkit-transform: translateY(-6px) scale(.75);
  transform: translateY(-6px) scale(.75);
}

/* LINE貼り付け labelの文字列改行 */
.karte .v-input.v-text-field.linefeed-label .v-label {
  white-space: pre-line;
  height: 38px;
}
.karte .v-input.v-text-field.linefeed-label textarea {
  margin-top: 42px !important;
}

/* 暫定診断 モーダル */
.modal-content-wrapper {
  font-size: 14px;
}

</style>

<script>
import { mapGetters, mapActions } from "vuex";
import outcomeOptions from "../../mixins/outcome_options.js";
import Diagnosis from "./Diagnosis";
import MidwifeDiagnosis from "./MidwifeDiagnosis";

export default {
  name: "KarteBody",
  components: {
    Diagnosis,
    MidwifeDiagnosis
  },
  mixins: [outcomeOptions],
  props: {
    active: Boolean
  },
  data: () => ({
    // jsonBuilderで値をto_iしているので未設定は0
    toolOptions: [
      { key: "未設定", value: 0 },
      { key: "LINE メッセージ", value: 1 },
      { key: "LINE 音声通話", value: 2 },
      { key: "LINE ビデオ通話", value: 3 },
      { key: "電話", value: 4 }
    ],
    movieDialog: false,
    movie: ""
  }),
  computed: {
    ...mapGetters([
      "karte",
      "reservation",
    ]),
  },
  methods: {
    ...mapActions(["updateKarte"]),
    copyFromKarteFields: function() {
      if(this.isCompletedReportingFields()){
        if(confirm("入力済みの報告が消えますが本当にコピーしますか？")){
          this.copyFields();
        }
      } else {
        this.copyFields();
      }
    },
    isCompletedReportingFields: function() {
      return    (this.karte.complaint_for_reporting != null && this.karte.complaint_for_reporting != "")
                || (this.karte.guidance_for_reporting != null  && this.karte.guidance_for_reporting != "")
                || (this.karte.outcome_for_reporting != null   && this.karte.outcome_for_reporting != "");

    },
    copyFields: function() {
      this.karte.complaint_for_reporting = this.karte.complaint;
      this.karte.guidance_for_reporting = this.karte.guidance;
      this.karte.outcome_for_reporting = this.outcomeOptionName(this.karte.outcome_id);
    },
    outcomeOptionName: function(id){
      if(!id) return null;
      return this.outcomeOptions.find(option => option.value == id).key;
    }
  },
};
</script>

<template>
  <v-container
    fluid
    class="search-area pa-8"
  >
    <v-row
      class="pa-4 search-condition"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-row
          class="ma-0"
        >
          <v-col
            cols="3"
            sm="3"
            md="3"
          >
            <h4>相談日</h4>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
          >
            <h4>ステータス</h4>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
          >
            <h4>担当相談員氏名（部分一致）</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            sm="3"
            md="3"
            class="pt-0 pb-0"
          >
            <v-menu
              min-width="auto"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="condition.date"
                  readonly
                  outlined
                  color="pink"
                  placeholder="日付"
                  prepend-inner-icon="event"
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="condition.date"
                no-title
                color="pink"
                first-day-of-week="1"
                locale="ja"
                :allowed-dates="allowedDate"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
            class="pt-0 pb-0"
          >
            <v-select
              v-model="condition.status"
              :items="daytimeAdviceSheetStatuses"
              item-text="key"
              item-value="value"
              label="ステータス"
              outlined
            />
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="condition.doctorFamilyName"
              outlined
              hide-details
              label="姓"
            />
          </v-col>
          <v-col
            cols="3"
            sm="3"
            md="3"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="condition.doctorFirstName"
              outlined
              hide-details
              label="名"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="6"
          >
            <v-btn
              tile
              dark
              color="pink"
              class="font-weight-bold"
              @click="search"
            >
              検索
            </v-btn>
            <v-btn
              tile
              dark
              outlined
              color="pink"
              class="font-weight-bold"
              @click="reset"
            >
              リセット
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="pa-0 search-result"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pl-0 pr-0"
      >
        <router-link
          v-for="sheet in daytimeAdviceSheetsResult"
          :key="sheet.id"
          :to="{ name: 'daytimeAdviceSearchView', params: { id: sheet.id }}"
          class="d-flex xs12 ma-1 card--hover result-sheet"
          style="border-radius: 2px"
        >
          <v-card
            flat
            ripple
            height="100%"
            width="100%"
          >
            <v-card-text>
              <div>
                <v-btn
                  small
                  :color="labelColor(sheet.status)"
                  class="ml-0"
                  dark
                  tile
                  depressed
                >
                  {{ sheet.statusI18n }}
                </v-btn>
                <b>{{ sheet.adviceDate }}</b>
              </div>
              <div><b>相談者:</b> {{ sheet.userName }}（ID: {{ sheet.userId }}/{{ sheet.couponName }}） | <b>担当者:</b> {{ sheet.doctorName }} | <b>対応時間:</b> {{ sheet.startAt }}{{ sheet.endAt }}</div>
              <div><b>主訴:</b> {{ sheet.complaint }}</div>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import commonMixin from "../mixins/daytime_advice_common.js";

export default {
  name: "DaytimeAdviceSearch",
  mixins: [commonMixin],
  computed: {
    ...mapGetters([
      "daytimeAdviceSheetsResult",
      "daytimeAdviceSheetStatuses",
      "currentDoctor",
    ]),
    ...mapGetters({
      condition: "daytimeAdviceSearchCondition",
    }),
    onOperatorDoctor: function () {
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    }
  },
  watch: {
  },
  created() {
    this.$store.dispatch("fetchDaytimeAdviceSheetStatuses");
  },
  methods: {
    search: function() {
      this.$store.dispatch("assignDaytimeAdviceSearchCondition", this.condition).then(() => {
        this.$store.dispatch("searchDaytimeAdviceSheets");
      });
    },
    reset: function() {
      this.$store.dispatch("resetDaytimeAdviceSearch");
    },
    allowedDate: function (val) {
      if (this.onOperatorDoctor) {
        return true;
      }

      let minAllowedDay = new Date(new Date().setHours(0, 0, 0, 0));
      minAllowedDay.setMonth(new Date(new Date().setHours(0, 0, 0, 0)).getMonth() - 3);

      return new Date(val).setHours(0, 0, 0, 0) >= minAllowedDay;
    }
  }
};
</script>
<style lang="scss" scoped>
.search-area {
  background-color: rgb(253, 238, 243);
}
.search-condition {
  background-color: #fff;
}
.result-sheet {
  text-decoration: none;
  .v-card__text {
    font-size: 12px;
  }
}
</style>

<template>
  <v-container
    fluid
    class="pa-0 pr-4"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0"
      >
        <v-container
          fluid
          class="pb-0"
        >
          <v-row>
            <v-col
              v-for="madia in daytimeAdviceImages"
              :key="madia.id"
              class="pb-0"
              md="1"
            >
              <v-card
                flat
                tile
              >
                <img
                  v-if="madia.extension == 'jpg'"
                  :src="madia.url"
                  style="max-width: 100%; height: 48px; cursor: pointer;"
                  oncontextmenu="return false;"
                  onselectstart="return false;"
                  onmousedown="return false;"
                  @click="showMedia(madia)"
                >
                <video
                  v-if="madia.extension == 'mp4'"
                  :src="madia.url"
                  style="max-width: 100%; height: 48px; cursor: pointer;"
                  controlsList="nodownload"
                  oncontextmenu="return false;"
                  @click="showMedia(madia)"
                />
                <a
                  v-if="madia.extension == 'pdf'"
                  :href="`/api/v1/daytime_advice_sheet_images/${madia.id}/download`"
                  target="_blank"
                >
                  <v-icon
                    size="48px"
                    style="border: 1px solid #CCC"
                  >
                    picture_as_pdf
                  </v-icon>
                </a>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- modal -->
    <v-dialog
      v-model="showDialog"
      max-width="60%"
    >
      <v-flex
        text-md-center
        style="position: relative"
      >
        <img
          v-if="extension == 'jpg'"
          :src="source"
          class="secure-image"
          style="display: block; width: 100%; max-height: 100%"
        >
        <video
          v-if="extension == 'mp4'"
          :src="source"
          controls
          controlsList="nodownload"
          oncontextmenu="return false;"
          style="max-width: 100%; max-height: 100%"
        />
        <v-btn
          absolute
          class="icon-button"
          color="gray lighten-4"
          fab
          x-small
          @click="closeDialog()"
        >
          ×
        </v-btn>
      </v-flex>
    </v-dialog>
    <!--// modal -->
  </v-container>
</template>
<style lang="scss" scoped>
.icon-button {
  top: 0;
  right: 0;
  font-size: 18px;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DaytimeAdviceImages",
  data: () => ({
    showDialog: false,
    source: "",
    extension: ""
  }),
  computed: {
    ...mapGetters([
      "daytimeAdviceImages",
      "daytimeAdviceMediaFile"
    ]),
  },
  watch: {
    "$route" () {
      this.fetchImages();
    }
  },
  created() {
    this.fetchImages();
  },
  methods: {
    showMedia: function(media) {
      this.$store.dispatch("viewDaytimeAdviceMedia", media.id).then(() => {
        this.$data.source = this.daytimeAdviceMediaFile.url;
        this.$data.extension = this.daytimeAdviceMediaFile.extension;
        this.$data.showDialog = true;
      });
    },
    closeDialog: function(e) {
      this.$data.showDialog = false;
    },
    fetchImages: function(){
      this.$store.dispatch("fetchDaytimeAdviceImages", {
        id: this.$route.params.id
      });
    }
  }
};
</script>

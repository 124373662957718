<template>
  <v-container
    fluid
    style="background-color: #fdeef3"
  >
    <v-row class="ma-2 white">
      <v-col>
        <v-container
          fluid
          class="pt-0"
        >
          <v-row class="pa-0">
            <v-col
              sm="12"
              md="12"
              class="pb-0"
            >
              <h3>{{ epdsAnswer.epds_answer.answered_at }}（産後{{ epdsAnswer.epds_answer.weeks_after_childbirth }}時点）</h3>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
              sm="12"
              md="12"
              class="pb-2"
            >
              <h4 class="pink--text">
                合計 {{ epdsAnswer.epds_answer.total_score }}点
              </h4>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
              sm="12"
              md="12"
              class="pb-2"
            >
              <v-list flat>
                <template v-for="q in epdsAnswer.epds_questions">
                  <p class="body-2 font-weight-bold">
                    {{ q.question_no }}. {{ q.question }}
                  </p>
                  <v-radio-group
                    column
                    :value="checkedId(epdsAnswer.epds_answer, q.id)"
                  >
                    <v-radio
                      v-for="c in q.epds_choices"
                      :key="c.id"
                      :label="c.choice"
                      :value="c.id"
                      color="pink"
                      readonly="true"
                    />
                  </v-radio-group>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <v-row class="pl-3 pr-3">
            <v-col
              sm="12"
              md="12"
              class="pa-0"
              style="border: solid 1px #bdbdbd;"
            >
              <v-card
                flat
                ripple
              >
                <v-card-title class="headline">
                  出産時の情報
                </v-card-title>
                <v-card-text>
                  <div><b>過去の出産回数</b>: {{ epdsAnswer.epds_answer.past_delivery_num }}回</div>
                  <div><b>最後の出産日または出産予定日</b>: {{ epdsAnswer.epds_answer.last_due_date }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
/* ラジオボタンの文字サイズ */
.v-radio .v-label {
  font-size: 14px !important;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EpdsView",
  computed: {
    ...mapGetters([
      "epdsAnswer",
    ]),
  },
  watch: {
    "$route" () {
      this.$store.dispatch("findEpdsAnswer", {
        epdsId: this.$route.params.id
      });
    }
  },
  created() {
    this.$store.dispatch("findEpdsAnswer", {
      epdsId: this.$route.params.id
    });
  },
  methods: {
    checkedId: (answer, id) => {
      var details = answer.epds_answer_details;
      var targetDetail = details.filter( function(detail){
        if (detail.question_id == id) return true;
      });
      return targetDetail[0].choice_id;
    }
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-2",attrs:{"grid-list-xs":"","fluid":""}},_vm._l((_vm.reservationList),function(reservationBlock,index){return _c('v-row',{key:index,attrs:{"noguide":""}},[_c('v-col',{staticClass:"pa-2 pr-0",attrs:{"cols":"1"}},[_c('v-card',{staticStyle:{"background-color":"#fafafa"},attrs:{"flat":""}},[_c('v-card-text',{domProps:{"textContent":_vm._s(reservationBlock.time)}})],1)],1),_vm._v(" "),_c('v-col',[_c('v-container',{staticClass:"pt-2",attrs:{"fluid":""}},_vm._l((reservationBlock.reservations),function(reservation){return _c('router-link',{key:reservation.id,staticClass:"d-flex xs12 ma-1 card--hover",staticStyle:{"border-radius":"2px"},attrs:{"to":{
            path: 'karte',
            query: {
              room: reservation.roomSection,
              date: _vm.$route.query.date,
              reservation_id: reservation.id,
            },
          },"tag":"v-flex"}},[_c('v-row',{staticStyle:{"background-color":"#bdbdbd"},attrs:{"noguide":""}},[_c('v-col',{staticStyle:{"border":"solid 1px #bdbdbd","padding":"1px","padding-left":"2px"},attrs:{"sm":"6","md":"1"}},[_c('v-card',{attrs:{"flat":"","ripple":"","height":"100%"}},[_c('v-card-text',{domProps:{"textContent":_vm._s(
                    reservation.department_name +
                    ' 相談ルーム ' +
                    reservation.room
                  )}})],1)],1),_vm._v(" "),_c('v-col',{staticStyle:{"padding-top":"1px","padding-bottom":"1px"},attrs:{"sm":"6","md":"1"}},[_c('v-row',{staticStyle:{"height":"50%"},attrs:{"noguide":""}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"border":"solid 1px #bdbdbd"},attrs:{"md":"12"}},[_c('v-card',{attrs:{"flat":"","ripple":"","height":"100%"}},[_c('v-card-text',{domProps:{"textContent":_vm._s(reservation.doctor)}})],1)],1)],1),_vm._v(" "),_c('v-row',{staticStyle:{"height":"50%"},attrs:{"noguide":""}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"border":"solid 1px #bdbdbd"},attrs:{"md":"12","height":"100%"}},[_c('v-card',{attrs:{"flat":"","ripple":"","height":"100%"}},[_c('v-card-text',{domProps:{"textContent":_vm._s(reservation.tool)}})],1)],1)],1)],1),_vm._v(" "),_c('v-col',{staticStyle:{"border":"solid 1px #bdbdbd","padding":"1px","padding-right":"2px"},attrs:{"sm":"12","md":"10"}},[_c('v-card',{attrs:{"flat":"","ripple":""}},[_c('v-card-text',[(reservation.department_type == 1)?_c('div',[(reservation.userType == 'ParentUser')?_c('div',[_c('syounikaUserInfo',{attrs:{"reservation":reservation}})],1):_vm._e(),_vm._v(" "),(reservation.userType == 'YouthUser')?_c('div',[_c('youthUserInfo',{attrs:{"reservation":reservation}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(
                      reservation.department_type == 2 ||
                      reservation.department_type == 3
                    )?_c('div',[_c('obstetricsUserInfo',{attrs:{"reservation":reservation}})],1):_vm._e()]),_vm._v(" "),(
                    reservation.cooperateWithClinic && _vm.isNotClinicDoctor()
                  )?[_c('div',{staticClass:"strong notice",staticStyle:{"padding-left":"16px"}},[_vm._v("\n                    ※このカルテはかかりつけクリニックに共有する必要のあるカルテです。\n                  ")])]:_vm._e(),_vm._v(" "),_c('v-card-text',{domProps:{"textContent":_vm._s(reservation.description)}})],2)],1)],1)],1)}),1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="period">
    <v-list-item
      link
      :to="{ path: 'karte', query: { room: period.room, date: $route.query.date, reservation_id: period.id }}"
      :disabled="!period.reserved"
      :class="{ 'pink lighten-4': period.id == $route.query.reservation_id && period.id }"
      color="pink lighten-5"
      ripple
    >
      <v-list-item-content>
        <v-list-item-title
          v-text="period.time"
        />
      </v-list-item-content>
    </v-list-item>
    <v-divider />
  </div>
</template>

<style lang="scss">
  .period .v-list-item--link:before {
    background-color: transparent;
  }
  .period a.v-list-item--disabled {
    color: rgba(25, 118, 210, .37) !important;
  }
  .period .v-list-item {
    min-height: 40px;
  }
  .period .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) .v-list-item__title {
    color: #e91e63 !important;
  }
  .period .v-list-item:not(.v-list-item--disabled) .v-list-item__title {
    color: #e91e63 !important;
  }
</style>

<script>
export default {
  name: "Period",
  props: {
    period: {
      type: Object,
      required: true
    },
  },
};
</script>


<template>
  <v-layout
    wrap
    class="pa-4"
  >
    <!-- カルテ情報 -->
    <v-flex
      v-if="karte.cooperate_with_clinic"
      d-flex
      md12
    >
      <v-flex
        md12
        class="clinic-area"
      >
        <template v-if="isCompletedReportingFields()">
          <div class="reporting">
            <h3>●主訴</h3>
            <div style="white-space: normal">
              {{ karte.complaint_for_reporting }}
            </div>
          </div>
          <div class="reporting">
            <h3>●指導内容</h3>
            <div style="white-space: normal">
              {{ karte.guidance_for_reporting }}
            </div>
          </div>
          <div class="reporting">
            <h3>●転帰</h3>
            <div style="white-space: normal">
              {{ karte.outcome_for_reporting }}
            </div>
          </div>
        </template>
        <template v-if="!isCompletedReportingFields()">
          <div class="reporting">
            深夜に共有設定が変更されたため事前問診のみ共有します。
          </div>
        </template>
        <div class="footer">
          ご不明な点などございましたら、メールにてご連絡ください。</br>
          なお、お問い合わせの際は個人情報保護の観点からメールに記載のカルテIDにてご連絡ください。</br>
          患者氏名等はご記載なさらないようお願いいたします。
          <div class="contact">
            <div class="company">
              株式会社Kids Public
            </div>
            <div><a href="mailto:contact@syounika.jp">contact@syounika.jp</a></div>
            <div>03-6774-7937</div>
            <div>（対応時間：平日10時〜18時）</div>
          </div>
        </div>
      </v-flex>
    </v-flex>
    <!--// カルテ情報 相談内容報告欄 -->
  </v-layout>
</template>

<style>
.clinic-area {
  border: 1px solid #bdbdbd;
  padding: 5px 10px;
}
.reporting {
  padding: 10px;
  font-size: 16px;
}
.reporting div{
  padding-left: 20px;
  white-space: pre;
}
.footer {
  padding-top: 15px;
  border-top: 1px dashed #bdbdbd;
}
.contact{
  padding-top: 20px;
}
.contact div.company{
  font-size: 16px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KarteClinic",
  computed: {
    ...mapGetters([
      "karte",
      "reservation",
    ])
  },
  methods: {
    isCompletedReportingFields: function() {
      return    (this.karte.complaint_for_reporting != null && this.karte.complaint_for_reporting != "")
                || (this.karte.guidance_for_reporting != null  && this.karte.guidance_for_reporting != "")
                || (this.karte.outcome_for_reporting != null   && this.karte.outcome_for_reporting != "");

    }
  },
};
</script>

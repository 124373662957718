<template>
  <div>
    <span>
      <b>ユーザID</b>
      <span class="pr-3">： {{ reservation.userId }}</span>
    </span>
    <span><b>クーポン</b>： {{ reservation.coupon }}</span>
    <br />
    <span><b>ユーザ</b>： {{ reservation.userName }}</span>
    <br />
    <span v-if="(reservation.userGender != null)">
        <b>ユーザの性別</b>：
        {{ reservation.userGender }}</span
      ><br />
    <span
      ><b>相談対象者の生年月日</b>：
      {{ reservation.userBirthdate }}</span
    ><br />
    <span>
      <v-btn
        v-if="reservation.firstAid"
        small
        color="grey darken-2"
        class="ml-0"
        outlined
        tile
        depressed
        >救急</v-btn
      >
      <v-btn
        small
        color="grey"
        class="ml-0"
        dark
        tile
        depressed
        >{{ reservation.category }}</v-btn
      > </span
    ><br />
    <template v-if="reservation.inPregnancy == 'pregnant'">
      <span>
        <b>過去の妊娠回数</b>:
        {{ reservation.pastPregnancyNum }}
        <span class="px-3">|</span>
        <b>過去の出産回数</b>:
        {{ reservation.pastDeliveryNum }} </span
      ><br />
      <span>
        <b>出産予定日</b>: {{ reservation.lastDueDate }} ({{
          reservation.childAge
        }}) </span
      ><br />
      <span>
        <b>妊娠方法</b>: {{ reservation.pregnantWayType }}
        <span class="px-3">|</span>
        <b>帝王切開の予定</b>:
        {{ reservation.toBeCsection }}
      </span>
    </template>
    <template
      v-if="reservation.inPregnancy == 'postpartum'"
    >
      <span>
        <b>過去の妊娠回数</b>:
        {{ reservation.pastPregnancyNum }}
        <span class="px-3">|</span>
        <b>過去の出産回数</b>:
        {{ reservation.pastDeliveryNum }} </span
      ><br />
      <span>
        <b>最後の出産日</b>:
        {{ reservation.lastDueDate }} ({{
          reservation.childAge
        }})</span
      ><br />
      <span>
        <b>出産方法</b>: {{ reservation.deliveryWayType }}
        <span class="px-3">|</span>
        <b>授乳状況</b>: {{ reservation.breastFeedingType }}
      </span>
    </template>
    <template v-if="reservation.inPregnancy == 'others'">
      <span>
        <b>過去の妊娠回数</b>:
        {{ reservation.pastPregnancyNum }}
        <span class="px-3">|</span>
        <b>過去の出産回数</b>:
        {{ reservation.pastDeliveryNum }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "ReservationListObstetricsUserInfo",
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
}
</script>

<template>
  <div>
    <span>
      <b>ユーザID</b>
      <span class="pr-3">： {{ reservation.userId }}</span>
    </span>
    <span><b>クーポン</b>： {{ reservation.coupon }}</span>
    <br />
    <span><b>ユーザネーム</b>： {{ reservation.userName }}</span>
    <br />
    <span><b>学年</b>： {{ reservation.reservedGrade }}</span>
    <br />
    <span><b>どんな気持ち？</b>： {{ reservation.expectedCare }}</span>
    <br />
    <span>
      <v-btn
        small
        color="grey"
        class="ml-0"
        dark
        tile
        depressed
        >{{ reservation.category }}</v-btn
      >
    </span>
    <div
      class="strong notice py-4"
    >
      ※ この相談は生徒本人による相談です ※
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationListYouthUserInfo",
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
}
</script>

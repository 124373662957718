<template>
  <v-container
    fluid
    class="pa-0 pr-4"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0 karte"
      >
        <v-textarea
          v-model="karte.trace"
          outlined
          name="line"
          label="LINE貼り付け欄
          *運営が行います"
          rows="10"
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pb-0 linefeed-label"
          hide-details
        />
        <v-card flat>
          <v-card-actions
            v-if="$route.name == 'karte'"
          >
            <v-btn
              :disabled="!active"
              block
              color="pink lighten-1"
              class="font-weight-bold ml-0 upload"
              onclick="document.getElementById('upload-button').click()"
              dark
            >
              画像／動画アップロード
              <v-icon right>
                publish
              </v-icon>
            </v-btn>
            <input
              id="upload-button"
              type="file"
              multiple="multiple"
              style="display: none"
              @change="onFileChange"
            >
          </v-card-actions>
          <v-container
            fluid
            class="pb-0"
          >
            <v-row>
              <v-col
                v-for="madia in karteImageList"
                :key="madia.id"
                class="pb-0"
                md="2"
              >
                <v-card
                  flat
                  tile
                >
                  <img
                    v-if="madia.extension == 'jpg'"
                    :src="madia.url"
                    style="max-width: 90%; height: 48px; cursor: pointer;"
                    oncontextmenu="return false;"
                    onselectstart="return false;"
                    onmousedown="return false;"
                    @click="showMedia(madia)"
                  >
                  <video
                    v-if="madia.extension == 'mp4'"
                    :src="madia.url"
                    style="max-width: 90%; height: 48px; cursor: pointer;"
                    controlsList="nodownload"
                    oncontextmenu="return false;"
                    @click="showMedia(madia)"
                  />
                  <a
                    v-if="madia.extension == 'pdf'"
                    :href="`/api/v1/karte_images/${madia.id}/download`"
                    target="_blank"
                  >
                    <v-icon
                      size="48px"
                      style="border: 1px solid #CCC"
                    >
                      picture_as_pdf
                    </v-icon>
                  </a>
                  <p
                    v-if="active"
                    @click="deleteFile({ 'image': madia, 'reservation_id': $route.query.reservation_id })"
                  >
                    削除
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- modal -->
    <v-dialog
      v-model="modalDialog"
      max-width="60%"
    >
      <v-flex
        text-md-center
        style="position: relative"
      >
        <img
          v-if="resourceExtension == 'jpg'"
          :src="resourcePath"
          class="secure-image"
          style="display: block; width: 100%; max-height: 100%"
        >
        <video
          v-if="resourceExtension == 'mp4'"
          :src="resourcePath"
          controls
          controlsList="nodownload"
          oncontextmenu="return false;"
          style="max-width: 100%; max-height: 100%"
        />
        <v-btn
          absolute
          class="icon-button"
          color="gray lighten-4"
          fab
          x-small
          @click="closeDialog()"
        >
          ×
        </v-btn>
      </v-flex>
    </v-dialog>
    <!--// modal -->
  </v-container>
</template>
<style>
.icon-button {
  top: 0;
  right: 0;
  font-size: 18px;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "KarteOperationFields",
  props: {
    active: Boolean
  },
  data: () => ({
    modalDialog: false,
    resourcePath: "",
    resourceExtension: ""
  }),
  computed: {
    ...mapGetters([
      "karte",
      "karteImageList",
      "reservation",
      "karteMediaFile"
    ]),
  },
  methods: {
    ...mapActions(["fetchKarteImages", "uploadFile", "deleteFile", "viewFile", "downloadFile"]),
    showMedia: function(madia) {
      this.viewFile(madia.id).then(() => {
        this.$data.resourcePath = this.karteMediaFile.url;
        this.$data.resourceExtension = this.karteMediaFile.extension;
        this.$data.modalDialog = true;
      });
    },
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileCount = e.target.files.length;

      for(let i = 0; i < fileCount; i++) {
        var formData = new FormData();
        formData.append("reservation_id", this.$route.query.reservation_id);
        formData.append("karte_id", this.karte.id);
        formData.append("filename", files[i]);
        this.uploadFile(formData);
      }
    },
    closeDialog: function(e) {
      this.$data.modalDialog = false;
    },
  },
  watch: {
    "$route" () {
      this.$store.dispatch("fetchKarteImages", {
        reservation_id: (this.$route.name == "karteView") ? this.$route.params.id : this.$route.query.reservation_id
      });
    }
  },
  created() {
    this.$store.dispatch("fetchKarteImages", {
      reservation_id: (this.$route.name == "karteView") ? this.$route.params.id : this.$route.query.reservation_id
    });
  }
};
</script>

<template>
  <v-container class="anytime-consultation-inquiry">
    <div>
      <h2>
        {{ consultation.title }}事前問診 (ID: {{ consultation.id }})
      </h2>
      <template v-if="isClinicDoctor">
        <b>質問日時：{{ consultation.askedAt }}</b>
        <b>回答日時：{{ answer.sentAt }}</b>
      </template>
    </div>
    <div :class="{'mt-4':true, 'inquery-grid-container': true, 'is-clinic-doctor': isClinicDoctor }">
      <v-card
        class="pa-4 inquery-grid-user"
        outlined
        tile
      >
        <anytime-consultation-user-info :consultation="consultation" />
      </v-card>
      <v-card
        v-if="isNotClinicDoctor"
        class="pa-4 inquery-grid-contract"
        outlined
        tile
      >
        <div>
          <b>相談方法別の利用可否</b>:
        </div>
        <div class="d-flex flex-wrap">
          <v-chip
            class="ma-1"
            label
            :color="consultation.useReservation ? '#FDEEF3' : 'default'"
          >
            夜間
            <v-icon
              v-if="consultation.useReservation"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useReservation"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="consultation.useAnytimeConsultation ? '#FDEEF3' : 'default'"
          >
            いつでも
            <v-icon
              v-if="consultation.useAnytimeConsultation"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useAnytimeConsultation"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="consultation.useDaytimeAdvice ? '#FDEEF3' : 'default'"
          >
            日中助産師
            <v-icon
              v-if="consultation.useDaytimeAdvice"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useDaytimeAdvice"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
        </div>

        <div>
          <b>相談員種別の利用可否</b>:
        </div>
        <div class="d-flex flex-wrap">
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.pediatricianEnabled
                ? '#FDEEF3'
                : 'default'
            "
          >
            小児科医
            <v-icon
              v-if="consultation.pediatricianEnabled"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.pediatricianEnabled"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.obstetricsEnabled
                ? '#FDEEF3'
                : 'default'
            "
          >
            産婦人科医
            <v-icon
              v-if="consultation.obstetricsEnabled"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.obstetricsEnabled"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.midwifeEnabled
                ? '#FDEEF3'
                : 'default'
            "
          >
            助産師
            <v-icon
              v-if="consultation.midwifeEnabled"
              color="rgba(0, 0, 0, .54)"
              small
              right
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.midwifeEnabled"
              small
              right
            >
              close
            </v-icon>
          </v-chip>
        </div>
      </v-card>
      <v-card
        class="pa-4 inquery-grid-detail"
        outlined
        tile
      >
        <div
          v-if="isNotClinicDoctor"
          class="mb-2"
        >
          <b>質問日時</b>： {{ consultation.askedAt }}
        </div>

        <div v-if="consultation.diseaseStartedAt">
          <b>いつから症状が出ているか</b>： {{ consultation.diseaseStartedAt }}
        </div>

        <div
          v-for="(value, key) in consultation.consultationDetails"
          :key="key"
        >
          <b>{{ key }}</b>: {{ value }}
        </div>

        <div class="mt-2">
          <span><b>医師への追加伝達事項</b>： </span><br>
          <div
            class="highlight-text"
            v-html="hightlightConvertedAdditionalDetails"
          />
        </div>

        <div
          v-if="isNotClinicDoctor"
          class="mt-2"
        >
          <span><b>添付画像</b>： </span>
          <span v-if="!consultation.image">
            なし
          </span>
          <div v-else>
            <img
              :src="consultation.image"
              style="max-width: 90%; height: 48px; cursor: pointer;"
              oncontextmenu="return false;"
              onselectstart="return false;"
              onmousedown="return false;"
              @click="showMedia"
            >
          </div>
        </div>

        <div v-if="consultation.cooperateWithClinic && isNotClinicDoctor">
          <div
            style="padding-left: 14px"
            class="strong notice pt-4"
          >
            <v-icon
              medium
              color="rgb(255, 57, 85)"
              style="margin-top: -2px"
            >
              info
            </v-icon>このカルテはかかりつけクリニックまたは自治体に共有する必要のあるカルテです。
          </div>
        </div>
        <br>
        <div v-if="consultation.youthConsultation">
          <div class="strong notice py-4">
            ※ この相談は生徒本人による相談です ※
          </div>
        </div>
      </v-card>
    </div>
    <!-- 画像拡大用 -->
    <v-container class="ma-0 pa-0">
      <v-row justify="center">
        <v-col class="ma-0 pa-0">
          <v-dialog
            v-model="imageDialog"
            max-width="60%"
          >
            <v-flex
              text-md-center
              style="position: relative"
            >
              <img
                v-if="imageUrl !== undefined"
                :src="imageUrl"
                class="secure-image"
                style="display: block; width: 100%; max-height: 100%"
              >
              <v-btn
                absolute
                class="icon-button"
                color="gray lighten-4"
                fab
                x-small
                @click="closeDialog()"
              >
                ×
              </v-btn>
            </v-flex>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <!--// 画像拡大用 -->
  </v-container>
</template>

<style lang="scss">
.inquery-grid-container{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas  : "user detail"
                         "contract  detail";

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  .is-minimum & {
    display: flex;
    flex-direction: column;
    .inquery-grid-contract {
      display: none;
    }
  }
  &.is-clinic-doctor{
    display: flex;
    flex-direction: column;
  }
}

.inquery-grid-user{
  grid-area: user;
}
.inquery-grid-contract{
  grid-area: contract;
}
.inquery-grid-detail{
  grid-area: detail;
}

.anytime-consultation-inquiry {
  .highlight-text {
    em {
      background-color: rgba(255, 255, 0, 0.4);
      font-style: normal;
      font-weight: bold;
    }
  }
}

.icon-button {
  top: 0;
  right: 0;
  font-size: 18px;
}
</style>

<script>
import { mapGetters } from "vuex";
import AnytimeConsultationUserInfo from "./AnytimeConsultationUserInfo";

function convertedHighlightText(original, keywords) {
  if (typeof original !== "string" || original.length == 0) {
    return "";
  }
  if (Array.isArray(keywords) && keywords.length >= 1) {
    keywords.forEach((str) => {
      original = original.replaceAll(str, "<em>" + str + "</em>");
    });
  }
  original = original.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
  return original;
}

export default {
  name: "AnytimeConsultationInquiry",
  components: {
    AnytimeConsultationUserInfo
  },
  props: {
    keywords: {
      type: Array,
      default: () => {[];}
    },
  },
  data: () => ({
    imageUrl: "",
    imageDialog: false,
  }),
  computed: {
    ...mapGetters({
      answer: "anytimeConsultationAnswer",
      consultation: "anytimeConsultation",
      currentDoctor: "currentDoctor"
    }),
    isClinicDoctor() {
      if (this.currentDoctor === null) {
        return false;
      }
      return this.currentDoctor.type == "Clinic::Doctor";
    },
    isNotClinicDoctor: function () {
      if (this.currentDoctor === null){
        return false;
      }
      return this.currentDoctor.type != "Clinic::Doctor";
    },
    hightlightConvertedAdditionalDetails: function () {
      return convertedHighlightText(this.$sanitize(this.consultation.additionalDetails), this.keywords);
    }
  },
  methods: {
    showMedia: function(e) {
      this.$data.imageDialog = true;
      this.$data.imageUrl = e.currentTarget.getAttribute("src");
    },
    closeDialog: function(e) {
      this.$data.imageDialog = false;
    },
  }
};
</script>

<template>
  <v-container class="pa-0 pl-6 daytime-advice-sheet-addon">
    <!-- 運営からの特記事項 -->
    <operator-special-note :active="active" />
    <!-- ユーザの特記事項 -->
    <parents-special-note :active="active" />
    <!-- 子の特記事項 -->
    <child-special-note :active="active" />

    <!--  日中助産師カルテ一覧  -->
    <daytime-advice-sheet-addon-list />

    <!-- エジンバラ産後うつ質問票 -->
    <epds-list v-if="user.id != null" :user-id="user.id" />

    <karte-list
      title="夜間相談カルテ"
      :karte-list="userKartes"
      :is-child-name-displayable="true"
    />

    <anytime-consultation-karte-list
      :title="'関連するいつでもカルテ一覧'"
      :anytime-consultations="userAnytimeConsultations"
      :is-child-name-displayable="true"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AnytimeConsultationKarteList from "./addon/AnytimeConsultationKarteList";
import EpdsList from "./addon/EpdsList";
import ParentsSpecialNote from "./addon/ParentsSpecialNote";
import ChildSpecialNote from "./addon/ChildSpecialNote.vue";
import KarteList from "./addon/SiblingKarteList";
import DaytimeAdviceSheetAddonList from "./addon/DaytimeAdviceSheetAddonList";
import OperatorSpecialNote from "./addon/OperatorSpecialNote";

export default {
  name: "DaytimeAdviceSheetAddon",
  components: {
    AnytimeConsultationKarteList,
    EpdsList,
    ParentsSpecialNote,
    ChildSpecialNote,
    KarteList,
    DaytimeAdviceSheetAddonList,
    OperatorSpecialNote,
  },
  props: {
    active: Boolean,
    userId: {
      type: Number,
      required: true,
    },
    childId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    cancelToken: axios.CancelToken,
    cancelSource: null,
  }),
  computed: {
    ...mapGetters([
      "user",
      "child",
      "userAnytimeConsultations",
      "userKartes",
      "userDaytimeAdviceSheets",
    ]),
  },
  watch: {
    $route() {
      this.clearData();
      this.fetchData();
    },
    userId: {
      handler: "findUser",
      immediate: true,
    },
    childId: {
      handler: "findChild",
      immediate: true,
    },
  },
  created() {
    this.fetchData();
  },
  destroyed() {
    this.clearData();
  },
  methods: {
    ...mapActions(["updateUser", "updateChild"]),
    fetchData: function () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = this.cancelToken.source();
      if (this.userId != null) {
        this.$store.dispatch("fetchUserKartes", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });
        this.$store.dispatch("fetchUserDaytimeAdviceSheets", {
          userId: this.userId,
          excludeSheetId: this.$route.params.id,
          cancelToken: this.cancelSource.token,
        });
        this.$store.dispatch("fetchUserAnytimeConsultations", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });
      }
    },
    clearData() {
      this.$store.dispatch("resetUser");
      this.$store.dispatch("resetChild");
      this.$store.dispatch("clearUserAnytimeConsultations");
      this.$store.dispatch("clearUserKartes");
      this.$store.dispatch("clearUserDaytimeAdviceSheets");
    },
    openKarte: (id) => {
      window.open("/reservations/" + id, "", "width=480, height=640");
    },
    findUser() {
      if (this.userId != null) {
        this.$store.dispatch("findUser", {
          userId: this.userId,
        });
      }
    },
    findChild() {
      if (this.childId != null) {
        this.$store.dispatch("findChild", {
          childId: this.childId,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/* 編集エリアの背景色 */
.daytime-advice-sheet-addon
  div.v-card
  div.v-input.v-text-field:not(.v-input--is-disabled) {
  background-color: #e8f2ff !important;
}

/* override */
.v-toolbar__title {
  color: #fff;
  font-size: 14px;
}
</style>

<style lang="scss">
.daytime-advice-sheet-addon
  div.v-input--is-disabled.v-text-field
  .v-text-field__slot
  textarea {
  color: #212121 !important;
}
</style>

<template>
  <div>
    <top-menu
      ref="top-menu"
      @logout="$emit('logout')"
    />
    <v-tabs
      v-model="activeTab"
      centered
      fixed-tabs
      show-arrows
      slider-color="pink"
      color="grey darken-4"
      class="top-tab"
    >
      <v-tab
        v-for="tab of tabs"
        :key="tab.id"
        @change="changeSource(tab.id)"
        :class="activeTab == tab.id ? 'ac-tab-active' : ''"
      >
        {{ tab.title }}
      </v-tab>
      <v-tab :to="{ name: 'anytimeConsultationSearch' }">
        回答済み検索
      </v-tab>
    </v-tabs>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-fade-transition mode="out-in">
            <router-view />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TopMenu from "./shared/TopMenu";
export default {
  name: "AnytimeConsultationHome",
  components: {
    TopMenu
  },
  data: () => ({
    activeTab: 0,
    tabs: [
      { id: 0, key: "syounika_incomplete", title: "小児科未回答", department: 1, complete: "0" },
      { id: 1, key: "obstetrics_incomplete", title: "産婦人科未回答", department: 2, complete: "0" },
      { id: 2, key: "midwife_incomplete", title: "助産師未回答", department: 3, complete: "0" },
      { id: 3, key: "syounika_complete", title: "小児科回答済(48H)", department: 1, complete: "1" },
      { id: 4, key: "obstetrics_complete", title: "産婦人科回答済(48H)", department: 2, complete: "1" },
      { id: 5, key: "midwife_complete", title: "助産師回答済(48H)", department: 3, complete: "1" },
    ]
  }),
  watch: {
    // タブだけでなくヘッダーメニューによる遷移も補足してタブをハイライトするため
    "$route.query.tab_id" (to) {
      this.changeSource(to);
    }
  },
  created() {
    this.changeSource(this.$route.query.tab_id);
  },
  methods: {
    changeSource: function (tabId) {
      // 回答済み検索は tabId なし
      if (tabId === undefined) {
        this.$router.push({name: "anytimeConsultationSearch"});
        tabId = 6; // 現在は max(tabs.id) + 1 の位置にある
      }
      else {
        let tab = this.tabs[tabId];
        this.$router.push({name: "anytimeConsultationList", query: { tab_id: tab.id, department: tab.department, complete: tab.complete }});
      }

      this.setActiveTab(tabId);
    },
    setActiveTab: function (id) {
      this.activeTab = id;
    },
  }
};
</script>

<style lang="scss">
.ac-tab-active {
  border-bottom: 3px solid #e91e63
}
</style>

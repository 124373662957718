<template>
  <div>
    <span class="pr-3">
      <b>子どもID</b>
      <span>： {{ reservation.childId }}</span>
    </span>
    <span>
      <b>ユーザID</b>
      <span class="pr-3">： {{ reservation.userId }}</span>
    </span>
    <span><b>クーポン</b>： {{ reservation.coupon }}</span>
    <br />
    <span><b>ユーザ</b>： {{ reservation.userName }}</span>
    <span
      v-if="reservation.needAttentionUser"
      class="btn-label"
    >
      <v-chip color="pink" small outlined
        >運営特記事項あり</v-chip
      >
    </span>
    <br />
    <span
      ><b>子</b>： {{ reservation.childBirthdate }} ({{
        reservation.childAge
      }}) ｜{{ reservation.childSex }}｜{{
        reservation.childName
      }}</span
    ><br/>
    <span>
      <v-btn v-if="reservation.firstAid" small color="white"
        >救急</v-btn
      >
      <v-btn small color="grey" dark>{{
        reservation.category
      }}</v-btn>
    </span>
  </div>
</template>

<script>
export default {
  name: "ReservationListSyounikaUserInfo",
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
}
</script>

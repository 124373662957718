<template>
  <div>
    <div>
      <b>子どもID</b>
      <span class="pr-3">： {{ consultation.childId }}</span>
      <span v-if="isClinicDoctor">{{ consultation.childFullName }}</span>
    </div>
    <div>
      <b>ユーザID</b>
      <span class="pr-3">： {{ consultation.userId }}</span>
      <span v-if="isClinicDoctor">{{ consultation.userFullName }}</span>
      <span v-if="consultation.needAttentionUser" class="btn-label">
        <v-chip color="pink" small outlined>
          <span class="hidden-sm-and-down">運営特記事項あり</span>
          <span class="hidden-md-and-up hidden-xs-only">特記事項あり</span>
          <span class="hidden-sm-and-up">特記あり</span>
        </v-chip>
      </span>
    </div>
    <div>
      <span><b>クーポン名</b>： {{ consultation.userCouponName }}</span>
    </div>
    <child-full-property
      :object="consultation"
      :is-child-name-displayable="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChildFullProperty from "../ChildFullProperty.vue";

export default {
  name: "AnytimeConsultationPediatricianUserInfo",
  components: {
    ChildFullProperty
  },
  props: {
    consultation: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      currentDoctor: "currentDoctor"
    }),
    isClinicDoctor() {
      if (this.currentDoctor === null){
        return false;
      }
      return this.currentDoctor.type == "Clinic::Doctor";
    },
  },
};
</script>

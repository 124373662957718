export default {
  methods: {
    labelColor(status){
      switch (status) {
      case 0:
        return "red";
      case 1:
        return "orange";
      case 2:
        return "green";
      case 3:
        return "grey lighten-2";
      }
    }
  }
};

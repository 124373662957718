<template>
  <v-container
    fluid
    class="anytime-consultation-new-search-result pt-0 pb-4 pr-6 pl-12"
  >
    <v-row
      no-gutters
      style="background: #fdeef3;"
    >
      <v-col
        cols="7"
        class="pa-2 d-flex align-center"
        style="border-right: 1px solid rgba(0, 0, 0, 0.38)"
      >
        <div>
          <template v-if="consultation.department_type == 'pediatrician'">
            <template v-if="consultation.type == 'AC::Syounika::CovidProject'">
              <!-- 思春期のケースを除く -->
              <b>年齢</b>:{{ consultation.childAge }} <br>
            </template>
          </template>
          <template v-else>
            <template v-if="consultation.inPregnancy == 'pregnant'">
              妊娠中（{{ consultation.childAge }}）<br>
            </template>
            <template v-else-if="consultation.inPregnancy == 'postpartum'">
              産後（{{ consultation.childAge }}）<br>
            </template>
          </template>
          <b>暫定診断</b>: {{ consultation.icd10BranchName }}<br>
          <b>気になっていること</b>: {{ consultation.userInterest }}<br>
        </div>
      </v-col>
      <v-col
        cols="3"
        class="pa-2"
        style="border-right: 1px solid rgba(0, 0, 0, 0.38)"
      >
        <b>{{ consultation.doctorName }}</b><br>
        {{ consultation.simpleAskedAt }}<br>
        ID:{{ consultation.id }}<br>
      </v-col>
      <v-col
        cols="2"
        class="pa-2 d-flex align-center justify-end"
      >
        <a @click="openAnytimeConsultationView(consultation.id)">
          <v-btn
            class="ma-0 pa-0 pink"
            style="color:#fff"
            small
          >詳細</v-btn>
        </a>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div
        ref="answertext"
        class="highlight-text-box"
        v-html="hightlightConvertedAnswer"
      />
    </v-row>
    <v-row
      v-if="consultation.explaination"
      no-gutters
    >
      <score-explanation
        :id="consultation.id"
        :score-obj="consultation.explaination"
      />
    </v-row>
  </v-container>
</template>

<style lang="scss">
.anytime-consultation-new-search-result {
  .highlight-text-box {
    width: 100%;
    height: 350px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    overflow: scroll;
    padding: 16px 12px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 18px;

    em {
      background-color: rgba(255, 255, 0, 0.4);
      font-style: normal;
      font-weight: bold;
    }
  }
}
</style>

<script>
import ScoreExplanation from "./ScoreExplanation";

function convertedHighlightText(original, keywords) {
  if (typeof original !== "string" || original.length == 0) {
    return "";
  }
  if (Array.isArray(keywords) && keywords.length >= 1) {
    keywords.forEach((str) => {
      original = original.replaceAll(str, "<em>" + str + "</em>");
    });
  }
  original = original.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
  return original;
}

export default {
  name: "AnytimeConsultationElasticSearchResult",
  components: {
    ScoreExplanation
  },
  props: {
    consultation: {
      type: Object,
      required: true
    },
    keywords: {
      type: Array,
      required: true
    },
  },
  computed: {
    hightlightConvertedAnswer: function () {
      return convertedHighlightText(this.$sanitize(this.consultation.answer), this.keywords);
    }
  },
  mounted: function () {
    const div = this.$refs.answertext;
    const firstEmElement = div.querySelector("em");
    if(firstEmElement){
      div.scrollTop = firstEmElement.offsetTop - div.offsetTop - 110;
    }
  },
  methods: {
    openAnytimeConsultationView: function (id) {
      let url = "/anytime_consultations/view/" + id;
      if(this.keywords.length > 0){
        url = url + "?" + new URLSearchParams({"k": this.keywords}).toString();
      }

      window.open(
        url,
        "",
        "width=480, height=640"
      );
    }
  }
};
</script>

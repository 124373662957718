<template>
  <div>
    <v-row class="ma-0 mb-1 pt-1">
      <v-menu
        ref="dateMenu"
        v-model="dateMenu"
        min-width="auto"
        transition="fade-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="daytimeAdviceSheet.childBirthdate"
            readonly
            :disabled="!editing"
            outlined
            placeholder="児の生年月日または出産予定日"
            prepend-inner-icon="event"
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          ref="picker"
          v-model="daytimeAdviceSheet.childBirthdate"
          no-title
          color="pink"
          first-day-of-week="1"
          :min="minDate"
          :max="maxDate"
          locale="ja"
          @change="saveDate"
        />
      </v-menu>
      <v-btn
        v-if="editing"
        color="pink"
        dark
        @click="calcAge"
      >
        計算する
      </v-btn>
    </v-row>
    <v-text-field
      id="child_age"
      v-model="daytimeAdviceSheet.childAge"
      outlined
      name="childAge"
      required
      label="児の年齢または妊娠週数"
      readonly
      :disabled="!editing"
      class="pt-1 pb-0"
      @click="open"
    />
    <modal name="selectChildAge">
      <div
        class="panel"
        @click.stop
      >
        <div class="modal-header">
          <h3>児の年齢または妊娠週数</h3>
          <span
            class="close"
            @click="close"
          >X</span>
        </div>
        <div class="panel-body">
          <div class="panel-box">
            <h4>＜妊娠週数＞</h4>
            <ul class="item-list">
              <li v-for="week in weeks">
                <span @click="onSelectWeeksOfPregnancy(week)">妊娠{{ week }}週</span>
              </li>
            </ul>
          </div>
          <div class="panel-box">
            <h4>＜児の年齢＞</h4>
            <div class="sub_category">
              <ul class="item-list">
                <li v-for="month in months">
                  <span @click="onSelectMonthOfBaby(month)">{{ month }}か月</span>
                </li>
              </ul>
              <ul class="item-list">
                <li v-for="year in years">
                  <span @click="onSelectChildAge(year)">{{ year }}歳</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DaytimeAdviceChildAge",
  model: {
    prop: "daytimeAdviceSheet"
  },
  props: {
    editing: Boolean,
    daytimeAdviceSheet: Object
  },
  data: () => ({
    weeks: Array.from({length: 39}, (_, i) => i + 4), // 4週〜42週
    months: [...Array(12).keys()], // 0〜11か月
    years: Array.from({length: 15}, (_, i) => i + 1), // 1〜15歳
    dateMenu: false,
  }),
  watch: {
    dateMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters([
      "autoCalcChildAge",
    ]),
    minDate: function() {
      // 今から15年前
      var date = new Date();
      date.setFullYear(date.getFullYear() - 15 );
      return date.toISOString().substr(0, 10);
    },
    maxDate: function() {
      // 今から1年後
      var date = new Date();
      date.setFullYear(date.getFullYear() + 1 );
      return date.toISOString().substr(0, 10);
    },
  },
  methods: {
    onSelectWeeksOfPregnancy(week){
      this.daytimeAdviceSheet.childAge = "妊娠" + week + "週";
      this.close();
    },
    onSelectMonthOfBaby(month) {
      this.daytimeAdviceSheet.childAge = month + "か月";
      this.close();
    },
    onSelectChildAge(year) {
      this.daytimeAdviceSheet.childAge = year + "歳";
      this.close();
    },
    open () {
      this.$modal.push("selectChildAge");
    },
    close () {
      this.$modal.pop();
    },
    saveDate (date) {
      this.$refs.dateMenu.save(date);
    },
    calcAge() {
      this.$store.dispatch("calcChildAge", {
        birthdate: this.daytimeAdviceSheet.childBirthdate
      }).then(() => {
        this.daytimeAdviceSheet.childAge = this.autoCalcChildAge;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.panel {
  min-width: 850px;
  min-height: 470px;
  background: #fff;
  padding: 0;

  .modal-header {
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;

    h3 {
      display: inline;
      margin-right: 5px;
    }
    .close {
      position: absolute;
      font-size: 150%;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
}

.panel-body {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
}

.panel-box {
  margin: 10px;
  height: 350px;
}

.panel-box:nth-child(1) {
  width: 60%;
}

.panel-box:nth-child(2) {
  width: 40%;
}

.sub_category {
  display: flex;
  flex-direction: row;
}

.sub_category ul {
  width: 50%;
}

.item-list {
  height: 370px;
  margin-top: 5px;
  padding: 5px;
  padding-left: 24px;
  display: flex;
  flex-flow: column wrap;

  li {
    margin-bottom: 3px;

    span {
      color: #4372c4;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <v-row class="operator-special-note">
    <v-col
      md="12"
      class="pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>運営からの特記事項</v-toolbar-title>
        </v-toolbar>
        <v-textarea
          v-if="active != null"
          v-model="user.operatorNotice"
          name="operator-notice"
          hide-details
          :readonly="!active"
          :disabled="!active"
          class="pt-1 pl-2"
        />
        <v-textarea
          v-else
          v-model="user.notice"
          disabled
          class="pt-1 pl-2"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<style>
/* disable 時の font-color を標準のグレーではなく #212121 へ上書きするスタイル */
.operator-special-note .v-input--is-disabled.v-text-field .v-text-field__slot textarea {
  color: #212121 !important;
}
</style>

<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #FFF;
  font-size: 14px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OperatorSpecialNote",
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(["user"]),
  }
};
</script>

import axios from "./index";


export default {
  fetchKartes(callback, params) {
    axios.get("/api/v1/kartes", {
      params: {
        reservation_id: params.reservationId
      },
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchSiblingKartes(callback, params) {
    axios.get("/api/v1/kartes/sibling", {
      params: {
        reservation_id: params.reservationId
      },
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  findKarte(callback, params) {
    axios.get("/api/v1/kartes/" + params.reservationId).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  updateKarte(params, successCallback, failureCallback) {
    axios.put("/api/v1/kartes/" + params.id, {
      karte: {
        complaint: params.complaint,
        interview: params.interview,
        diagnosis: params.diagnosis,
        icd10_branch_id: params.branch_id,
        icd10_original_disease: params.original_disease,
        guidance: params.guidance,
        outcome_id: params.outcome_id,
        message: params.message,
        tool_id: params.tool_id,
        trace: params.trace,
        is_connected: params.is_connected,
        complaint_for_reporting: params.complaint_for_reporting,
        guidance_for_reporting: params.guidance_for_reporting,
        outcome_for_reporting: params.outcome_for_reporting
      }
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(() => {
      successCallback();
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },

  fetchKarteImages(callback, params) {
    axios.get("/api/v1/karte_images", {
      params: {
        reservation_id: params.reservation_id
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  uploadFile(callback, params) {
    axios.post("/api/v1/karte_images", params, {
      headers: { "Content-Type": "multipart/form-data" }
    }
    ).then(() => {
      callback();
    }).catch(error => {
      console.log(error);
    });
  },

  deleteFile(callback, params) {
    axios.delete("/api/v1/karte_images/" + params.image.id).then(() => {
      callback();
    }).catch(error => {
      console.log(error);
    });
  },

  show(callback, mediaId) {
    axios.get(`/api/v1/karte_images/${mediaId}`).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  findKarteReferenceInfo(callback, params) {
    axios.get("/api/v1/karte_reference_info", {
      params: {
        reservationId: params.reservationId
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
};

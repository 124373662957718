import "../../../../node_modules/vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import sanitizeHTML from "sanitize-html";
import linkify from "vue-linkify";

Vue.use(Vuetify);
Vue.directive("linkified", linkify);
Vue.prototype.$sanitize = sanitizeHTML;

const options = {};
export default new Vuetify(options);

<template>
  <v-container class="pl-6 pr-6">
    <div>
      <v-card
        flat
        ripple
      >
        <v-card-text>
          <div>
            <template v-if="isCompletedReportingFields">
              <div class="reporting">
                <h3>●主訴</h3>
                <div style="white-space: normal">
                  {{ answer.forReporting.complaint }}
                </div>
              </div>
              <div class="reporting">
                <h3>●暫定診断</h3>
                <div style="white-space: normal">
                  {{ answer.forReporting.diagnosis }}
                </div>
              </div>
              <div class="reporting">
                <h3>●回答文</h3>
                <div
                  style="white-space: normal"
                  v-html="structuredContent"
                />
              </div>
            </template>
            <template v-if="!isCompletedReportingFields">
              <div class="reporting">
                深夜に共有設定が変更されたため事前問診のみ共有します。
              </div>
            </template>            
            <div class="footer">
              ご不明な点などございましたら、メールにてご連絡ください。</br>
              なお、お問い合わせの際は個人情報保護の観点からメールに記載のカルテIDにてご連絡ください。</br>
              患者氏名等はご記載なさらないようお願いいたします。
              <div class="contact">
                <div class="company">
                  株式会社Kids Public
                </div>
                <div><a href="mailto:contact@syounika.jp">contact@syounika.jp</a></div>
                <div>03-6774-7937</div>
                <div>（対応時間：平日10時〜18時）</div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<style>
.clinic-area {
  border: 1px solid #bdbdbd;
  padding: 5px 10px;
}
.reporting {
  padding: 10px;
  font-size: 16px;
}
.reporting div{
  padding-left: 20px;
  white-space: pre;
}
.footer {
  padding-top: 15px;
  border-top: 1px dashed #bdbdbd;
}
.contact{
  padding-top: 20px;
}
.contact div.company{
  font-size: 16px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AnytimeConsultationClinic",
  computed: {
    ...mapGetters({
      answer: "anytimeConsultationAnswer",
    }),
    isCompletedReportingFields: function() {
      if (this.answer === null || this.answer.forReporting === null || typeof this.answer.forReporting === "undefined" ){
        return false
      }
      return (this.answer.forReporting.content != null && this.answer.forReporting.content != "")
                || (this.answer.forReporting.complaint != null  && this.answer.forReporting.complaint != "")
                || (this.answer.forReporting.diagnosis != null   && this.answer.forReporting.diagnosis != "")
    },
    structuredContent: function () {
      const content = this.$sanitize(this.answer.forReporting.content)
      if (typeof content !== "string" || content.length == 0) {
        return "";
      }
      return content.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>")
    }
  }
};
</script>

<template>
  <v-container
    grid-list-xs
    fluid
    class="pa-2 pt-0 pb-0"
  >
    <v-container
      grid-list-xs
      fluid
      class="pa-2 pt-0 pb-0"
      v-if="anytimeConsultations"
    >
      <v-card
        flat
        class="anytime-consultation-search-info pl-2 pt-2"
      >
        <v-card-text class="row pa-0">
          {{ order }}に表示 / {{ anytimeConsultations.length }}件ヒット
          <span
            class="ml-auto"
            v-if="incompleted">
            <v-switch
              v-model="filtered"
              hide-details
              flat
              label="担当分を表示"
              class="ma-0 filtered-toggle"
              :class="anytimeConsultationFilteredFlag? 'filtered' : 'unfiltered'"
              @change="switchFilterdFlag"
            ></v-switch>
          </span>
        </v-card-text>
      </v-card>
      <v-container fluid>
        <div
          v-for="consultation in anytimeConsultations"
          :key="consultation.id"
          class="anytime-consultation-info"
        >
          <anytimeConsultationDetail
            :anytimeConsultation = "consultation"
            :tabId="tabId"
          />
        </div>

        <!-- 担当分を表示 -->
        <!-- 未回答 -->
        <!-- 担当科目 -->
        <div v-if="anytimeConsultationFilteredFlag && incompleted && currentDepartmentType">
          <!-- 受付時間中 -->
          <div v-if="onShift">
            <anytimeConsultationAnswerNotice
              bg_color=#F9E5EC
              text_color=#E00051
              icon=mdi-alert-circle-outline
              title=受付時間中
              detail=相談が増える可能性があります。
            >
            </anytimeConsultationAnswerNotice>
          </div>
          <!-- 受付終了 -->
          <div v-else-if="anytimeConsultations.length > 0">
            <anytimeConsultationAnswerNotice
              bg_color=#D7D7D7
              text_color=#8A8A8A
              icon=mdi-bell-outline
              title=受付終了
              detail=これ以上相談は増えません。
            >
            </anytimeConsultationAnswerNotice>
          </div>
          <!-- 勤務完了 -->
          <div v-else-if="noticeExpiration">
            <anytimeConsultationAnswerNotice
              bg_color=#ECF7E4
              text_color=#69B72E
              icon=mdi-emoticon-happy-outline
              title=勤務完了
              detail=ご担当ありがとうございました。
            >
            </anytimeConsultationAnswerNotice>
          </div>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<style lang="scss">
  .anytime-consultation-search-info {
    background-color: #fafafa;
    margin: 2px;

    .v-card__text {
      background-color: #fafafa;
    }
  }

  .anytime-consultation-info {
    border: solid 1px #888888;
    background-color: white;
    border-radius: 2px;

    .v-card {
      border: solid 1px #bdbdbd !important;
      height: 100%;
    }
  }
  .filtered {
    label {
      color: #212121 !important;
    }
  }
  .unfiltered {
    label {
      color: #ccc !important;
    }

  }
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import AnytimeConsultationDetail from "./shared/AnytimeConsultationDetailCard";
import AnytimeConsultationAnswerNotice from "./shared/ac_shift/AnytimeConsultationAnswerNotice";

export default {
  name: "AnytimeConsultationList",
  components: {
    AnytimeConsultationDetail,
    AnytimeConsultationAnswerNotice
  },
  data: () => ({
    tabId: 0,
    order: "古い順",
    incompleted: true,
    departmentType: null,
    filtered: true,
    dapartmentTypes: {
      "1": "pediatrician",
      "2": "obstetrician",
      "3": "midwife",
    },
    currentDepartmentType: false,
  }),
  computed: {
    ...mapGetters([
      "anytimeConsultations",
      "currentDoctor",
      "anytimeConsultationFilteredFlag",
      "onShift",
      "noticeExpiration"
    ]),
    onDutyDoctor: function () {
      return this.currentDoctor && this.currentDoctor.type == "Onduty::Doctor";
    },
    operatorDoctor: function () {
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
  },
  methods: {
    ...mapActions([
      "fetchAnytimeConsultations",
      "fetchAssignedAnytimeConsultations",
      "fetchCompletedAnytimeConsultations",
      "assignFilteredFlag",
      "fetchCurrentDoctor",
      "isOnAnytimeConsultationShift",
      "isAnswerNoticeExpiration"
    ]),
    loadList: function () {
      this.incompleted = this.$route.query.complete == 0 ? true : false
      this.departmentType = this.$route.query.department;
      this.currentDepartmentType = (this.departmentType in this.dapartmentTypes && this.dapartmentTypes[this.departmentType] === this.currentDoctor.department_type);
      if(this.incompleted){
        this.loadIncompletedList()
      } else {
        // 完了済
        this.fetchCompletedAnytimeConsultations({
          department: this.departmentType
        });
        this.order = "新しい順";
      }
      this.tabId = this.$route.query.tab_id;
    },
    loadIncompletedList: function() {
      // 未完了
      if((this.onDutyDoctor || this.operatorDoctor ) && this.filtered){
        // アサインされた相談のみ
        this.fetchAssignedAnytimeConsultations({
          department: this.departmentType
        });
      } else {
        // すべての相談
        this.fetchAnytimeConsultations({
          department: this.departmentType
        });
      }
      this.order = "古い順";
    },
    switchFilterdFlag: function() {
      this.assignFilteredFlag(this.filtered)
      this.loadIncompletedList()
    },
    isOnShift: function () {
      this.isOnAnytimeConsultationShift({
        doctor_id: this.currentDoctor.id
      });
    },
    isNoticeExpiration: function () {
      this.isAnswerNoticeExpiration({
        doctor_id: this.currentDoctor.id
      });
    },
  },
  watch: {
    "$route.query" () {
      this.loadList();
      this.isOnShift();
      this.isNoticeExpiration();
    }
  },
  created() {
    this.fetchCurrentDoctor().then(() => {
      if(this.onDutyDoctor || this.operatorDoctor){
        this.filtered = this.anytimeConsultationFilteredFlag;
      } else {
        this.filtered = false
      }
      this.loadList();
      this.isOnShift();
      this.isNoticeExpiration();
    });
  },
};
</script>

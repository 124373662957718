<template>
  <div class="notice-container" :style="'background-color: ' + bg_color">
    <div class="notice-wrapper">
      <span class="notice-title" :style="'display: flex; justify-content: center; align-items: center; color: ' + text_color">
        <v-icon class="notice-icon" :style="'color: ' + text_color" size="32px">{{ icon }}</v-icon>{{ title }}
      </span>
      <span class="notice-detail" :style="'color: ' + text_color">{{ detail }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notice-container {
  width: 100%;
  height: 110px;
  margin-top: 10px;
  position: relative;

  div.notice-wrapper {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    span.notice-title {
      font-family: YuGothic;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 33.8px */
      letter-spacing: 1.04px;

      .notice-icon {
        width: 32px;
        height: 32px;
      }
    }

    span.notice-detail {
      font-family: YuGothic;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 23.4px */
      letter-spacing: 0.72px;
    }
  }
}
</style>

<script>
export default {
  name: "AnytimeConsultationAnswerNotice",
  props: {
    bg_color: {
      type: String,
      required: true
    },
    text_color: {
      type: String,
      required: true
    },
    accepting: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    detail: {
      type: String,
      required: true
    }
  }
};
</script>

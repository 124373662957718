<template>
  <v-container
    grid-list-xs
    fluid
    class="pa-2 pt-0 pb-0"
  >
    <div class="search-area center">
      <div class="mt-2 pl-2 departmentSearch">
        <div class="search-title">
          相談員種別
        </div>
        <v-row class="pa-0">
          <v-checkbox
            v-for="option in departmentTypeOptions"
            :key="option.value"
            v-model="departmentTypes"
            :value="option.value"
            :label="option.text"
            class="col-2 ma-0"
            hide-details
          />
        </v-row>
      </div>
      <div class="pl-2 icd10Search">
        <div class="search-title">
          暫定診断キーワード（部分一致）
        </div>
        <v-row>
          <v-col cols="4">
            <v-text-field
              id="icd10SearchField"
              v-model="icd10Keyword"
              hide-details
              outlined
              dense
              style="background-color: white !important"
              @input="searchIcd10"
              @focus="onFocusIcd10Search(true)"
              @blur="onFocusIcd10Search(false)"
            />

            <div
              v-if="icd10SearchFocus"
              class="icd10ResultField"
            >
              <div :class="{ icd10ListWrap: icd10SearchResults.length !== 0 }">
                <div class="icd10ListArea">
                  <div
                    v-for="result in icd10SearchResults"
                    :key="result.id"
                  >
                    <p @click="onSelectDiagnosis(result)">
                      <a>{{ result.name }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="icd10Notice">
          ※ タイプして診断名例が出てこなかった場合は、かなでの入力、漢字での入力などを試してみてください。<br>
          ※ 独自診断名も対応していますので、診断名例が出なくともキーワードで検索してみてください。
        </div>
      </div>
      <div class="mt-4 pl-2">
        <div class="search-title">
          回答者氏名（部分一致）
        </div>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="doctorFamilyName"
              label="姓"
              dense
              hide-details
              style="background-color: white !important"
              outlined
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="doctorFirstName"
              label="名"
              dense
              hide-details
              style="background-color: white !important"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="answer-keyword mt-4 pl-2">
        <div class="search-title">
          回答文章キーワード検索（スペース区切りで複数キーワードの入力が可能です）
        </div>
        <v-row>
          <v-col cols="2">
            <v-radio-group
              v-model="answerKeywordSearchRule"
              row
            >
              <v-radio
                label="全てを含む"
                value="and"
              />
              <v-radio
                label="いずれかを含む"
                value="or"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="answerKeywords"
              dense
              hide-details
              outlined
              style="background-color: white !important"
            />
          </v-col>
        </v-row>
      </div>
      <div class="pl-2">
        <div class="search-title">
          <span>追加の検索条件</span>
          <v-btn
            color="primary"
            text
            @click.stop="searchDetailDialog = true"
          >
            {{ hasDetailConditions ? "あり" : "なし" }}（条件を追加する）
          </v-btn>
        </div>

        <v-dialog
          v-model="searchDetailDialog"
          max-width="70%"
        >
          <v-container
            fluid
            class="pa-8 detail-dialog"
            style="position: relative; background-color: #DDD"
          >
            <v-row>
              <div class="search-title">
                【小児科】子どもの年齢
              </div>
            </v-row>
            <v-row class="pa-0">
              <v-checkbox
                v-for="option in childAgeOptions"
                :key="option.value"
                v-model="childAgeRanges"
                :value="option.value"
                :label="option.text"
                class="ma-2 mb-4 mr-4"
                hide-details
              />
            </v-row>
            <v-row class="mt-2">
              <div class="search-title">
                【小児科】子どもの性別
              </div>
            </v-row>
            <v-row class="pa-0">
              <v-checkbox
                v-for="option in childGenderOptions"
                :key="option.value"
                v-model="childGenders"
                :value="option.value"
                :label="option.text"
                class="ma-2 mb-4 mr-4"
                hide-details
              />
            </v-row>
            <v-row class="pt-4">
              ※ 産婦人科/助産師の追加条件検索は未実装です。ご希望ありましたら運営までお知らせください。
            </v-row>
            <v-row justify="center">
              <v-btn
                tile
                color="pink"
                @click="searchDetailDialog = false"
              >
                設定する
              </v-btn>
            </v-row>
            <v-btn
              absolute
              class="icon-button"
              fab
              x-small
              text
              @click="searchDetailDialog = false"
            >
              ×
            </v-btn>
          </v-container>
        </v-dialog>
      </div>

      <div class="force-all pl-2">
        <v-checkbox
          v-model="forceAll"
          label="すべての結果を返す（デフォルト100件のみ）"
        />
      </div>

      <v-btn
        tile
        :disabled="departmentTypes.length == 0"
        @click="updateList()"
      >
        検索
      </v-btn>
    </div>

    <div
      v-if="previousKeys !== null"
      class="results"
    >
      <div class="search-keys">
        <b>検索条件</b><br>
        相談員種別: {{ displayDepartmentTypes(previousKeys.department_types) }}<br>
        暫定診断キーワード: {{ previousKeys.icd10_keyword }}<br>
        回答者: (姓: {{ previousKeys.doctor_family_name }}, 名: {{ previousKeys.doctor_first_name }})<br>
        回答検索キーワード: {{ previousKeys.answer_keywords.join(" " + previousKeys.answer_keyword_search_rule + " ") }}<br>
        追加条件: {{ displayOptionConditions() }}<br>
        全件検索: {{ previousKeys.force_all ? 'する' : 'しない' }}
      </div>

      <anytimeConsultationSearchResults
        :anytime-consultations="anytimeConsultations"
        :order="'新しい順'"
        :previous-keys="previousKeys"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AnytimeConsultationSearchResults from "./shared/AnytimeConsultationSearchResults";

export default {
  name: "AnytimeConsultationSearch",
  components: {
    AnytimeConsultationSearchResults
  },
  data: () => ({
    departmentTypes: [1],
    departmentTypeOptions: [
      {text: "小児科", value: 1},
      {text: "産婦人科", value: 2},
      {text: "助産師", value: 3},
    ],
    childAgeOptions: [
      {text: "生後1ヶ月未満", value: 1},
      {text: "1ヶ月以上〜7ヶ月未満", value: 2},
      {text: "7ヶ月以上〜1歳未満", value: 3},
      {text: "1歳以上〜6歳未満", value: 4},
      {text: "6歳以上", value: 5},
    ],
    childGenderOptions: [
      {text: "男の子", value: 0},
      {text: "女の子", value: 1},
    ],
    icd10Keyword: "",
    icd10SearchFocus: false,
    icd10SearchResults: [],
    doctorFamilyName: "",
    doctorFirstName: "",
    answerKeywords: "",
    answerKeywordSearchRule: "and",
    forceAll: false,
    previousKeys: null,
    searchDetailDialog: false,
    childAgeRanges: [],
    childGenders: []
  }),
  computed: {
    ...mapGetters([
      "anytimeConsultations",
      "anytimeConsultationSearchKeys"
    ]),
    splitKeywords: function(){
      return this.answerKeywords.split(/\s|'　'/).filter(v => v);
    },
    hasDetailConditions: function(){
      return this.childAgeRanges.length > 0 || this.childGenders.length > 0;
    }

  },
  methods: {
    ...mapActions([
      "searchAnytimeConsultations",
      "assignAnytimeConsultationSearchKeys"
    ]),
    updateList: function () {
      this.previousKeys = {
        department_types: this.departmentTypes,
        icd10_keyword: this.icd10Keyword,
        doctor_family_name: this.doctorFamilyName,
        doctor_first_name: this.doctorFirstName,
        answer_keyword_search_rule: this.answerKeywordSearchRule,
        answer_keywords: this.splitKeywords,
        child_genders: this.childGenders,
        child_age_ranges: this.childAgeRanges,
        force_all: this.forceAll
      };
      this.searchAnytimeConsultations(this.previousKeys);
    },
    onSelectDiagnosis(item) {
      this.icd10Keyword = item.name;
      this.icd10SearchResults = []; // 検索リストを消す
    },
    onFocusIcd10Search(focus) {
      // サジェストされた診断名をクリックしようとしてもフォーカスが外れるが、
      // すぐに icd10SearchFocus = false するとサジェスト結果が消えてしまい
      // クリックイベントがトリガーされず onSelectDiagnosis が動作しないため、
      // クリックイベントがトリガーされて onSelectDiagnosis が動作するのを少し待つ
      setTimeout(() => {
        this.icd10SearchFocus = focus;
      }, 300);
    },
    searchIcd10: function (inputValue) {
      axios.get("/api/v1/icd10/all_types/search", {
        params: {
          keyword: this.icd10Keyword
        }
      }).then(response => {
        this.icd10SearchResults = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    departmentTypeName(departmentType) {
      return departmentType === 1 ? "syounika" : "obstetrics";
    },
    displayDepartmentTypes(departmentTypes) {
      return departmentTypes.map(departmentType => this.departmentTypeOptions.find(d => d.value === departmentType).text).join(", ");
    },
    displayOptionConditions(){
      let conditions = "";
      if(this.previousKeys.child_genders.length == 0 && this.previousKeys.child_age_ranges .length == 0){
        conditions = "なし";
      } else {
        let condArray = [];
        if(this.previousKeys.child_age_ranges .length > 0){
          condArray = condArray.concat(this.previousKeys.child_age_ranges.map( age => this.childAgeOptions.find(a => a.value === age).text));
        }
        if(this.previousKeys.child_genders.length > 0){
          condArray = condArray.concat(this.previousKeys.child_genders.map( gender => this.childGenderOptions.find(g => g.value === gender).text));
        }
        conditions = condArray.join(", ");
      }
      return conditions;
    }
  },
  watch: {
    // icd10 について保留
    // departmentType: function (to, from) {
    //  if (this.departmentTypeName(to) === this.departmentTypeName(from)) return;

    // // departmentTypeName が異なると、同じ icd10 カテゴリは使えないので初期化する
    //  this.icd10Keyword = "";
    //}
  },
  created() {
    if (this.anytimeConsultationSearchKeys === null) return;

    // 検索条件を復元
    this.previousKeys = this.anytimeConsultationSearchKeys;
    this.departmentTypes = this.previousKeys.department_types;
    this.icd10Keyword = this.previousKeys.icd10_keyword;
    this.doctorFamilyName = this.previousKeys.doctor_family_name;
    this.doctorFirstName = this.previousKeys.doctor_first_name;
    this.answerKeywordSearchRule = this.previousKeys.answer_keyword_search_rule;
    this.answerKeywords = this.previousKeys.answer_keywords.join(" ");
    this.childGenders = this.previousKeys.child_genders;
    this.childAgeRanges = this.previousKeys.child_age_ranges;
    this.forceAll = this.previousKeys.force_all;
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "anytimeConsultation") {
      // 詳細画面へ遷移する場合は戻ってくる際に再利用できるよう検索条件を保存しておく
      this.assignAnytimeConsultationSearchKeys(this.previousKeys);
    }
    else {
      // 他の画面に行く場合は検索条件を削除
      this.assignAnytimeConsultationSearchKeys(null);
    }
    next();
  },
};
</script>

<style lang="scss">
.search-area {
  padding: 20px;
  background-color: #fcf0f4;

  .departmentSearch {
    .v-input--selection-controls__input input:checked{
      opacity: 100;
    }

    label.v-label {
      color: rgb(0, 0, 0) !important;
    }
  }

  .icd10Search {
    .icd10ResultField {
      position: relative;
    }

    .icd10ListWrap {
      z-index: 2;
      position:absolute;
      width: 100%;
      top: 0;

      .icd10ListArea {
        max-height: 150px;
        border: solid 1px #000000;
        margin:0 auto;
        overflow: auto;
        background-color: #ffffff;
      }
      p {
        margin: 0;
        text-align: left;
        border: solid 1px #d2d2d2;

        a {
          display: inline-block;
          width: 100%;

          &:hover {
            background-color: #ccc;
          }
        }
      }
    }
  }

  .answer-keyword {
    label.v-label {
      color: rgb(0, 0, 0) !important;
    }
  }

  .force-all {
    label.v-label {
      color: rgb(0, 0, 0) !important;
    }
  }
}

.detail-dialog{
    .v-input--selection-controls__input input:checked{
      opacity: 100;
    }
    label.v-label {
      color: rgb(0, 0, 0) !important;
    }
}
.results {
  padding: 20px;

  .search-keys {
    padding: 3px;
    border: solid 1px #000000;
  }
}
</style>

<template>
  <v-row
    noguide
    class="anytime-consultation-info-detail"
  >
    <v-col
      cols="3"
      class="py-0"
    >
      <v-row
        class="full-height"
        noguide
      >
        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card
            flat
            class="px-2"
          >
            <v-btn
              :to="{ name: 'anytimeConsultation', params: { id: anytimeConsultation.id, tab_id: tabId }}"
              block
              class="ml-0"
              color="pink"
            >
              詳細
            </v-btn>
          </v-card>
        </v-col>
        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card flat>
            <v-card-text
              :class="currentDoctor.id === anytimeConsultation.assignDoctorId ? 'pink--text' : ''"
              v-text="anytimeConsultation.assignDoctorName"
            />
          </v-card>
        </v-col>

        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card flat>
            <v-card-text v-text="anytimeConsultation.id" />
          </v-card>
        </v-col>

        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card flat>
            <v-card-text v-text="anytimeConsultation.status" />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card flat>
        <v-card-text>
          <div>
            <span>{{ anytimeConsultation.title }}</span>
            <br>
            <span><b>質問日時</b>： {{ anytimeConsultation.askedAt }}</span>
            <br>
            <span v-if="anytimeConsultation.userInterest"><b>気になっていること</b>: {{ anytimeConsultation.userInterest }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card flat>
        <v-card-text>
          <anytime-consultation-user-info :consultation="anytimeConsultation" />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card flat>
        <v-card-text>
          <div>
            <span><b>暫定診断</b>： {{ anytimeConsultation.icd10BranchName }}</span>
            <br>
            <span><b>回答者</b>： {{ anytimeConsultation.doctorName }}</span>
            <br>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss">
  .anytime-consultation-info {
    .anytime-consultation-info-detail {
      margin: 6px;

      .full-height {
        min-height: 100%;
        height: 100%;
      }
    }
  }
</style>

<script>
import { mapGetters } from "vuex";
import AnytimeConsultationUserInfo from "./AnytimeConsultationUserInfo";

export default {
  name: "AnytimeConsultationCard",
  components: {
    AnytimeConsultationUserInfo
  },
  props: ["anytimeConsultation", "tabId"],
  computed: {
    ...mapGetters([
      "currentDoctor",
    ]),
  },
};
</script>

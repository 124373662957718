<template>
  <div>
    <top-menu
      ref="top-menu"
      @logout="$emit('logout')"
    />
    <v-tabs
      fixed-tabs
      show-arrows
      color="grey darken-4"
      class="top-tab"
    >
      <v-tabs-slider class="pink" />
      <v-tab
        :to="{ name: 'daytimeAdviceRoom' }"
      >
        担当
      </v-tab>
      <v-tab
        v-if="isOperator()"
        :to="{ name: 'daytimeAdviceMonitor' }"
      >
        モニタリング
      </v-tab>
      <v-tab
        :to="{ name: 'daytimeAdviceSearch' }"
      >
        検索
      </v-tab>
    </v-tabs>
    <v-container
      fluid
      class="pa-0 list-container"
    >
      <v-row
        justify="center"
        class="fill-height"
      >
        <v-col class="fill-height pt-0 pb-0">
          <v-fade-transition mode="out-in">
            <router-view class="daytime_advice" />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
.daytime_advice {
  font-size: 14px;
}
</style>
<script>
import { mapGetters } from "vuex";
import TopMenu from "./shared/TopMenu";

export default {
  name: "DaytimeAdviceHome",
  components: {
    TopMenu
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters(["currentDoctor"]),
  },
  watch: {
  },
  created() {
  },
  methods: {
    isOperator: function(){
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
  }
};
</script>

<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-toolbar
      height="64px"
      color="transparent"
    >
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col md="12">
            <v-toolbar-items>
              <v-btn
                :to="{ path: '/shifts/edit', query: { date: $route.query.date } }"
                large
                color="pink lighten-1"
                class="shift-edit-btn pr-8 pl-8"
              >
                編集する
                <v-icon right>
                  edit
                </v-icon>
              </v-btn>
              <div
                v-if="shiftsUpdateSkippings.length > 0"
                style="padding-left:20px"
                class="blue--text"
              >
                <div v-for="skippingInfo in shiftsUpdateSkippings">
                  {{ skippingInfo }}
                </div>
              </div>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-data-table
            :headers="shifts.header"
            :items="shifts.body"
            item-key="period"
            hide-default-footer
            disable-pagination
            class="elevation-1 shift-list"
          >
            <template v-slot:item="{item}">
              <tr class="text-no-wrap">
                <td>{{ item.period }}</td>
                <td
                  v-for="shift in item.shifts_by_period"
                  :key="shift.id"
                >
                  {{ findDoctorName(shift.doctor_id) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopMenu from "./shared/TopMenu";

export default {
  name: "Shift",
  components: {
    TopMenu
  },
  computed: {
    ...mapGetters(["shifts", "doctors", "shiftsUpdateSkippings"]),
  },
  watch: {
    "$route" () {
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    }
  },
  created() {
    this.$store.dispatch("fetchAllDoctors").then(()=>{
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    });
  },
  methods: {
    changeDate(d) {
      this.$router.push({ name: "shift", query: { date: d } });
    },
    findDoctorName(doctor_id) {
      let index = this.doctors.findIndex((v) => v.id === doctor_id);
      if(index == -1) {
        return "";
      }
      return this.doctors[index]["name"];
    }
  }
};
</script>
<style lang="scss">
.v-data-table.shift-list tbody td:first-child,
.v-data-table.shift-list tbody td:not(:first-child),
.v-data-table.shift-list tbody th:first-child,
.v-data-table.shift-list tbody th:not(:first-child),
.v-data-table.shift-list thead td:first-child,
.v-data-table.shift-list thead td:not(:first-child),
.v-data-table.shift-list thead th:first-child,
.v-data-table.shift-list thead th:not(:first-child) {
    padding: 0 24px;
}
.v-data-table.shift-list thead tr {
  height: 46px;
}
.v-data-table.shift-list th {
  font-weight: 500;
}
.v-data-table.shift-list table {
  margin-top: 10px;
}
.v-data-table.shift-list th:first-child,
.v-data-table.shift-list td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.v-data-table.shift-list tbody td {
    font-weight: 400;
    font-size: 13px;
}
.shift-edit-btn {
  font-size: 15px !important;
}
</style>

<template>
  <v-row>
    <v-col md="12" class="pt-2 pb-2">
      <v-card>
        <v-toolbar color="#4D4D4D" height="32">
          <v-toolbar-title>カルテ一覧</v-toolbar-title>
        </v-toolbar>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col
              class="pt-0 pb-0"
              style="overflow-y: auto; max-height: 400px"
            >
              <v-card v-for="karte in karteList" :key="karte.id">
                <v-card-text>
                  <div>
                    <p class="mb-1 d-flex">
                      {{ karte.date }} {{ karte.time }} 〜 （{{
                        karte.doctorName
                      }}）
                      <v-img
                        v-if="karte.message"
                        src="/send_off.png"
                        max-width="60"
                        max-height="22"
                      />
                    </p>
                    <p class="mb-1">主訴：{{ karte.complaint }}</p>
                    <p v-if="karte.diagnosis" class="mb-1">
                      旧暫定診断：{{ karte.diagnosis }}
                    </p>
                    <p class="mb-1">
                      ICD10暫定診断：{{ karte.icd10BranchName }}
                    </p>
                    <p class="mb-1">転帰：{{ karte.outcomeId }}</p>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <a @click="openKarte(karte.reservationId)">
                    <v-btn small color="pink lighten-1">
                      閲覧
                      <v-icon right>visibility</v-icon>
                    </v-btn>
                  </a>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #FFF;
  font-size: 14px;
}
</style>

<script>
export default {
  name: "MyKarteList",
  props: {
    karteList: {
      type: Array,
      required: true
    }
  },
  methods: {
    openKarte: (id) => {
      window.open("/reservations/" + id, "", "width=480, height=640");
    }
  }
};
</script>

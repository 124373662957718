<template>
  <v-container
    fluid
    class="pa-0 pr-4 daytime-advice-sheet-body"
  >
    <v-row
      class="ml-2"
      justify="left"
      align="center"
    >
      <span style="font-size: 14px;">
        <b>相談方法別の利用可否</b>:
      </span>
      <div>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useReservation ? '#FDEEF3' : 'default'"
        >
          夜間
          <v-icon
            v-if="daytimeAdviceSheet.useReservation"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useReservation"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useAnytimeConsultation ? '#FDEEF3' : 'default'"
        >
          いつでも
          <v-icon
            v-if="daytimeAdviceSheet.useAnytimeConsultation"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useAnytimeConsultation"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useDaytimeAdvice ? '#FDEEF3' : 'default'"
        >
          日中助産師
          <v-icon
            v-if="daytimeAdviceSheet.useDaytimeAdvice"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useDaytimeAdvice"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
      </div>
    </v-row>
    <v-row
      class="ml-2"
      justify="left"
      align="center"
    >
      <span style="font-size: 14px;">
        <b>相談員種別の利用可否</b>:
      </span>
      <div>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.pediatricianEnabled ? '#FDEEF3' : 'default'"
        >
          小児科医
          <v-icon
            v-if="daytimeAdviceSheet.pediatricianEnabled"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.pediatricianEnabled"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.obstetricsEnabled ? '#FDEEF3' : 'default'"
        >
          産婦人科医
          <v-icon
            v-if="daytimeAdviceSheet.obstetricsEnabled"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.obstetricsEnabled"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.midwifeEnabled ? '#FDEEF3' : 'default'"
        >
          助産師
          <v-icon
            v-if="daytimeAdviceSheet.midwifeEnabled"
            color="rgba(0, 0, 0, .54)"
            small
            right
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.midwifeEnabled"
            small
            right
          >
            close
          </v-icon>
        </v-chip>
      </div>
    </v-row>
    <v-row
      class="karte"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0"
      >
        <daytime-advice-child-age
          v-model="daytimeAdviceSheet"
          :editing="editing"
        />
        <v-textarea
          v-model="daytimeAdviceSheet.complaint"
          outlined
          name="complaint"
          required
          label="主訴（必須）*"
          rows="2"
          :readonly="!editing"
          :disabled="!editing"
          class="pt-1 pb-0"
        />
        <midwife-diagnosis
          v-if="daytimeAdviceSheet.departmentType === 3"
          v-model="daytimeAdviceSheet"
          :active="editing"
        />
        <v-select
          v-model="daytimeAdviceSheet.outcome"
          outlined
          name="outcome"
          :items="outcomeOptions"
          item-text="key"
          item-value="value"
          required
          :readonly="!editing"
          :disabled="!editing"
          label="転帰（必須）*"
        />
        <v-checkbox
          v-model="daytimeAdviceSheet.noAdvice"
          color="pink"
          :disabled="!editing"
          :class="!editing && daytimeAdviceSheet.noAdvice ? 'disable-checkbox' : ''"
          label="相談なし"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import outcomeOptions from "../../mixins/outcome_options.js";
import MidwifeDiagnosis from "./MidwifeDiagnosis";
import DaytimeAdviceChildAge from "./DaytimeAdviceChildAge";

export default {
  name: "DaytimeAdviceSheetBody",
  components: {
    MidwifeDiagnosis,
    DaytimeAdviceChildAge
  },
  mixins: [outcomeOptions],
  props: {
    editing: Boolean
  },
  computed: {
    ...mapGetters([
      "daytimeAdviceSheet",
    ]),
  }
};
</script>


<style lang="scss">
.daytime-advice-sheet-body {
  /* テキストエリア/セレクトボックス ラベル色 */
  div.v-input--is-disabled.v-text-field .v-text-field__slot label.v-label--is-disabled {
    color: #C0C0C0 !important;
  }

  /* テキストエリア/セレクトボックス 枠 */
  .v-text-field--outlined:not(.v-input--is-focused) fieldset,
  .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover fieldset {
    border: 2px solid #C0C0C0 !important;
  }

  /* 保存された入力文字 */
  div.v-input--is-disabled.v-text-field .v-text-field__slot input,
  div.v-input--is-disabled.v-text-field .v-text-field__slot textarea,
  div.v-input--is-disabled.v-text-field .v-select__selection--disabled {
    color: #212121 !important;
  }

  /* 時間内連絡なしチェックONの場合のみ適用されるラベル色 */
  .disable-checkbox label.v-label.theme--light {
    color: #212121 !important;
  }

  /* 編集エリアの背景色 */
  div.v-input.v-text-field:not(.v-input--is-disabled) .v-input__slot {
    background-color: #E8F2FF !important;
  }

  /** チャット入力欄の背景色が上記スタイルに影響されるため強制的に白に */
  #chat-input div.v-input.v-text-field:not(.v-input--is-disabled) .v-input__slot {
    background-color: #FFF !important;
  }

  /* テキストボックスの入力位置 */
  .v-text-field__slot input#child_age {
    margin-top: 15px !important;
  }
}
</style>

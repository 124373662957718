<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-toolbar
      height="64px"
      color="transparent"
    >
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col md="12">
            <v-toolbar-items>
              <v-btn
                :to="{ path: '/shifts', query: { date: $route.query.date } }"
                large
                outlined
                color="pink lighten-1"
                class="mr-4 shift-edit-btn cancel-btn pr-8 pl-8"
              >
                キャンセル
                <v-icon right>
                  reply
                </v-icon>
              </v-btn>
              <v-btn
                large
                color="pink lighten-1"
                class="shift-edit-btn pr-8 pl-8"
                @click="updateShiftWrapper()"
              >
                保存する
                <v-icon right>
                  send
                </v-icon>
              </v-btn>
              <div
                v-if="errorMessage != null"
                style="padding-left:20px"
                class="pink--text"
              >
                {{ errorMessage }}<br>
                {{ shiftsUpdateErrorDetail }}
              </div>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-data-table
            :headers="shifts.header"
            :items="shifts.body"
            hide-default-footer
            disable-pagination
            class="elevation-1 shift-list"
          >
            <template v-slot:item="{item}">
              <tr
                :key="item.period"
                class="text-no-wrap"
              >
                <td>{{ item.period }}</td>
                <td v-for="shift in item.shifts_by_period">
                  <v-autocomplete
                    v-if="shift.status != 'reserved'"
                    :key="shift.id"
                    v-model="shift.doctor_id"
                    :name="`shifts[${shift.id}]`"
                    :items="filterdDoctors(shift.department_type)"
                    item-text="name"
                    item-value="id"
                    required
                    class="doctor-select"
                  />
                  <div v-if="shift.status == 'reserved'">
                    {{ findDoctorName(shift.doctor_id) }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopMenu from "./shared/TopMenu";

export default {
  name: "Shift",
  components: {
    TopMenu
  },
  computed: {
    ...mapGetters([
      "shifts",
      "doctors",
      "shiftsUpdateErrorDetail"
    ]),
    ...mapGetters({doctorsByDepartmentType: "doctorsByDepartmentType"}),
  },
  data: () => ({
    syounikaDoctors: null,
    obstetricsDoctors: null,
    midwifeDoctors: null,
    errorMessage: null,
    shiftsUpdateResult: true,
  }),
  methods: {
    ...mapActions(["updateShifts"]),
    updateShiftWrapper: function() {
      this.updateShifts(this.shifts).then(() => {
        this.$router.push({ path: "/shifts", query: { date: this.$route.query.date }});
      }).catch(() => {
        // 保存エラー
        this.shiftsUpdateResult = false;
        this.errorMessage = "シフトの変更に失敗しました。管理者にお問い合わせください。";
      });
    },
    changeDate(d) {
      this.$router.push({ name: "shift", query: { date: d } });
    },
    findDoctorName(doctor_id) {
      let index = this.doctors.findIndex((v) => v.id === doctor_id);
      if(index == -1) {
        return "";
      }
      return this.doctors[index]["name"];
    },
    filterdDoctors(department_type){
      switch(department_type){
      case 1:
        return this.syounikaDoctors;
      case 2:
        return this.obstetricsDoctors;
      case 3:
        return this.midwifeDoctors;
      }
    },
    createFilterdDoctors(){
      this.syounikaDoctors =  this.doctorsByDepartmentType(1);
      this.obstetricsDoctors = this.doctorsByDepartmentType(2);
      this.midwifeDoctors = this.doctorsByDepartmentType(3);
    }
  },
  watch: {
    "$route" () {
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    }
  },
  created() {
    this.$store.dispatch("fetchAllDoctors").then(()=>{
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    });
    this.$store.dispatch("fetchDoctorsByDepartmentType").then(()=>{
      this.createFilterdDoctors();
    });
    this.$store.dispatch("clearSkippings");
  }
};
</script>
<style lang="scss">
.shift-edit-btn.cancel-btn.v-btn--active:before,
.shift-edit-btn.cancel-btn.v-btn:hover:before,
.shift-edit-btn.cancel-btn.v-btn:focus:before {
  background-color: #ec407a !important;
}

.doctor-select{
  white-space: nowrap;
}

.shift-list{
  .v-select__slot {
    input {
      width: initial !important;
    }
  }
}
</style>

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from "vue";
import VueThinModal from "vue-thin-modal";

import router from "../vue/routes";

import store from "../vue/stores";
import vuetify from "../vue/plugins/vuetify";

import App from "../vue/App.vue";

Vue.use(VueThinModal);
import("../../../node_modules/vue-thin-modal/dist/vue-thin-modal.css");

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    store,
    vuetify,
    components: { App }
  });

  document.body.appendChild(document.createElement("app"));
  app.$mount("app");
});

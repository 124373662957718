<template>
  <div>
    <span>
      <b>子どもID</b>
      <span class="pr-3">： {{ reservation.childId }}</span>
    </span>
    <span>
      <b>ユーザID</b>
      <span class="pr-3">： {{ reservation.userId }}</span>
    </span>
    <span><b>クーポン</b>： {{ reservation.coupon }}</span>
    <br />
    <span><b>ユーザ</b>： {{ reservation.userName }}</span>
    <br />
    <child-full-property
      :object="reservation"
      :is-child-name-displayable="true"
    /><br />
    <span>
      <v-btn
        v-if="reservation.firstAid"
        small
        color="grey darken-2"
        class="ml-0"
        outlined
        tile
        depressed
        >救急</v-btn
      >
      <v-btn
        small
        color="grey"
        class="ml-0"
        dark
        tile
        depressed
        >{{ reservation.category }}</v-btn
      >
    </span>
  </div>
</template>

<script>
import ChildFullProperty from "../ChildFullProperty.vue";

export default {
  name: "KarteSyounikaUserInfo",
  components: {
    ChildFullProperty
  },
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
}
</script>

<template>
  <div>
    <span>
      <b>ユーザID</b>
      <span class="pr-3">： {{ reservation.userId }}</span>
    </span>
    <span><b>クーポン</b>： {{ reservation.coupon }}</span>
    <br />
    <span><b>ユーザネーム</b>： {{ reservation.userName }}</span>
    <br />
    <span><b>学年</b>： {{ reservation.reservedGrade }}</span>
    <br />
    <span
      v-if="reservation.needAttentionUser"
      class="btn-label"
    >
      <v-chip color="pink" small outlined
        >運営特記事項あり</v-chip
      >
    </span>
    <span>
      <v-btn small color="grey" dark>{{
        reservation.category
      }}</v-btn>
    </span>
  </div>
</template>

<script>
export default {
  name: "ReservationListYouthUserInfo",
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
}
</script>

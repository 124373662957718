<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>EPDS結果</v-toolbar-title>
        </v-toolbar>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col
              class="pt-0 pb-0"
              style="overflow-y: auto; max-height: 400px;"
            >
              <v-card
                v-for="answer in epdsAnswerList"
                :key="answer.id"
              >
                <v-card-text>
                  <div>
                    <p class="mb-1 d-flex">
                      {{ answer.answered_at }}（産後{{ answer.weeks_after_childbirth }}時点）
                    </p>
                    <p class="mb-1">
                      回答：{{ answer.total_score }}点
                    </p>
                    <p
                      v-for="iq in answer.important_questions"
                      class="mb-1"
                    >
                      第{{ iq.question_no }}項目：{{ iq.score }}点
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <a @click="openEpds(answer.id)">
                    <v-btn
                      small
                      color="pink lighten-1"
                    >
                      閲覧
                      <v-icon right>visibility</v-icon>
                    </v-btn>
                  </a>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #FFF;
  font-size: 14px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EpdsList",
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "epdsAnswerList",
    ]),
  },
  watch: {
    userId: {
      handler: "fetchEpdsAnswers",
      immediate: true
    }
  },
  destroyed() {
    this.$store.dispatch("clearEpdsAnswers");
  },
  methods: {
    openEpds: (id) => {
      window.open("/epds/" + id, "", "width=480, height=640");
    },
    fetchEpdsAnswers () {
      if (this.userId != null) {
        this.$store.dispatch("fetchEpdsAnswers", {
          user_id: this.userId
        });
      }
    }
  }
};
</script>

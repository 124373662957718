<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-tabs
      v-model="activeTab"
      centered
      fixed-tabs
      show-arrows
      color="grey darken-4"
      class="top-tab"
    >
      <v-tabs-slider class="pink" />
      <v-tab :to="{ path: 'list', query: { date: $route.query.date }}">
        予約一覧
      </v-tab>
      <v-tab
        v-for="(room) in firstReservationList.rooms"
        :key="room.section"
        @change="changeTab(room)"
      >
        {{ room.name }}
      </v-tab>
    </v-tabs>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-fade-transition mode="out-in">
            <router-view />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopMenu from "./shared/TopMenu";

export default {
  name: "Tab",
  components: {
    TopMenu
  },
  data: () => ({
    activeTab: "/reservations/list"
  }),
  computed: {
    ...mapGetters(["users", "firstReservationList"]),
  },
  watch: {
    "$route" () {
      this.$store.dispatch("fetchFirstReservationsOfDay", {
        date: this.$route.query.date
      }).then(() => {
        this.findTabIndex();
      });
    }
  },
  created() {
    this.$store.dispatch("fetchFirstReservationsOfDay", {
      date: this.$route.query.date
    }).then(() => {
      this.findTabIndex();
    });
  },
  methods: {
    changeDate(d) {
      this.$router.push({ name: "list", query: { date: d, reservation_id: "" } });
    },
    changeTab: function(room){
      this.$router.push({ name: "karte", query: { room: room.section, date: this.$route.query.date, reservation_id: this.firstReservationList.reservations[room.section] }});
    },
    findTabIndex: function(){
      if(this.$route.name == "karte"){
        var index = this.firstReservationList.rooms.findIndex(room => room.section == this.$route.query.room) + 1;
        this.activeTab = index;
      } else {
        this.activeTab = this.$route.Fullpath;
      }
    },
  },
};
</script>

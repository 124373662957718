<template>
  <v-row>
    <v-col
      md="12"
      class="pt-2 pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>日中助産師カルテ</v-toolbar-title>
        </v-toolbar>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col
              class="pt-0 pb-0"
              style="overflow-y: auto; max-height: 400px;"
            >
              <v-card
                v-for="sheet in userDaytimeAdviceSheets"
                :key="sheet.id"
              >
                <v-card-text>
                  <div>
                    <p class="mb-1 d-flex">
                      {{ sheet.adviceDate }} {{ sheet.startAt }}（{{ sheet.doctorName }}）
                    </p>
                    <p class="mb-1">
                      主訴：{{ sheet.complaint }}
                    </p>
                    <p class="mb-1">
                      ICD10暫定診断：{{ sheet.icd10BranchName }}
                    </p>
                    <p class="mb-1">
                      転帰：{{ sheet.outcomeI18n }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <a @click="openDaytimeAdviceSheet(sheet.id)">
                    <v-btn
                      small
                      color="pink lighten-1"
                    >
                      閲覧
                      <v-icon right>visibility</v-icon>
                    </v-btn>
                  </a>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DaytimeAdviceSheetAddonList",
  computed: {
    ...mapGetters([
      "userDaytimeAdviceSheets"
    ]),
  },
  methods: {
    openDaytimeAdviceSheet: (id) => {
      window.open("/daytime_advices_sheets/" + id, "", "width=480, height=640");
    },
  }
};
</script>
<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #FFF;
  font-size: 14px;
}
</style>

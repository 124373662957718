<template>
  <v-container fluid style="background-color: #fdeef3" class="karte">
    <div v-if="errorMessage != null" class="pink--text">
      {{ errorMessage }}
    </div>
    <v-row noguide>
      <v-col v-if="reservation.loading" class="col-md-12 text-center" style="background-color: #ffffff">
        <img src="/loading.gif">
      </v-col>
      <v-col v-if="!reservation.loading" md="2" class="pr-2">
        <period-list />
      </v-col>

      <v-col
        v-if="!reservation.loading && reservation.date && viewableKarte()"
        :md="isNotClinicDoctor() ? 7 : 10"
        class="pa-3"
      >
        <div class="karte-detail pa-2">
          <v-row class="pa-4 pt-1 pb-0">
            <v-col>
              <h1>{{ reservation.date }} {{ reservation.time }}</h1>
            </v-col>
            <template v-if="karte.load_finish">
              <template v-if="isNotClinicDoctor()">
                <v-col v-if="!editing" cols="4">
                  <v-btn
                    color="pink"
                    class="font-weight-bold"
                    block
                    dark
                    @click="startEditing()"
                  >
                    編集する
                  </v-btn>
                </v-col>
                <v-col v-if="editing" cols="3">
                  <v-btn outlined color="pink" block @click="cancel()">
                    キャンセル
                  </v-btn>
                </v-col>
                <v-col v-if="editing" cols="3">
                  <v-btn
                    color="pink"
                    class="font-weight-bold"
                    block
                    dark
                    @click="postKarte()"
                  >
                    保存する
                  </v-btn>
                </v-col>
              </template>
            </template>
            <template v-else>
              <v-col cols="4">
                <v-btn disabled block tile> カルテ読込中... </v-btn>
              </v-col>
            </template>
          </v-row>
          <!-- 事前問診情報 -->
          <v-row>
            <v-container fluid class="pa-6">
              <v-row noguide class="pl-6" no-gutters>
                <v-col>
                  <p class="mb-0">事前問診</p>
                </v-col>
              </v-row>
              <v-row noguide class="pl-6 pr-6">
                <v-col
                  sm="12"
                  md="12"
                  style="
                    border: solid 1px #bdbdbd;
                    border-bottom: none;
                    padding: 0;
                  "
                >
                  <v-btn
                    :color="changeColorByTool()"
                    class="font-weight-bold"
                    dark
                    depressed
                  >
                    {{ reservation.tool }}
                  </v-btn>
                  {{ reservation.doctor }}
                </v-col>
              </v-row>
              <v-row noguide class="pl-6 pr-6">
                <v-col
                  sm="6"
                  md="6"
                  style="border: solid 1px #bdbdbd; border-right: none"
                  class="pt-0 pb-0 pl-0 pr-0"
                >
                  <v-card flat ripple style="border-radius: 0">
                    <v-card-text>
                      <div v-if="reservation.department_type == 1">
                        <template v-if="reservation.userType == 'ParentUser'">
                          <syounikaUserInfo
                            :reservation="reservation"
                          />
                        </template>
                        <template v-if="reservation.userType == 'YouthUser'">
                          <youthUserInfo
                            :reservation="reservation"
                          />
                        </template>
                      </div>
                      <div v-if="reservation.department_type >= 2">
                        <obstetricsUserInfo
                          :reservation="reservation"
                        />

                      </div>
                      <div>
                        <span> <b>相談方法別の利用可否</b>: </span>
                        <div>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.useReservation ? '#FDEEF3' : 'default'
                            "
                          >
                            夜間
                            <v-icon
                              v-if="reservation.useReservation"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.useReservation"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.useAnytimeConsultation
                                ? '#FDEEF3'
                                : 'default'
                            "
                          >
                            いつでも
                            <v-icon
                              v-if="reservation.useAnytimeConsultation"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.useAnytimeConsultation"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.useDaytimeAdvice
                                ? '#FDEEF3'
                                : 'default'
                            "
                          >
                            日中助産師
                            <v-icon
                              v-if="reservation.useDaytimeAdvice"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.useDaytimeAdvice"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                        </div>
                      </div>
                      <div>
                        <span> <b>相談員種別の利用可否</b>: </span>
                        <div>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.pediatricianEnabled ? '#FDEEF3' : 'default'
                            "
                          >
                            小児科医
                            <v-icon
                              v-if="reservation.pediatricianEnabled"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.pediatricianEnabled"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.obstetricsEnabled
                                ? '#FDEEF3'
                                : 'default'
                            "
                          >
                            産婦人科医
                            <v-icon
                              v-if="reservation.obstetricsEnabled"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.obstetricsEnabled"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                          <v-chip
                            class="ma-1"
                            label
                            :color="
                              reservation.midwifeEnabled
                                ? '#FDEEF3'
                                : 'default'
                            "
                          >
                            助産師
                            <v-icon
                              v-if="reservation.midwifeEnabled"
                              color="rgba(0, 0, 0, .54)"
                              small
                              right
                            >
                              mdi-circle-outline
                            </v-icon>
                            <v-icon
                              v-if="!reservation.midwifeEnabled"
                              small
                              right
                            >
                              close
                            </v-icon>
                          </v-chip>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  sm="6"
                  md="6"
                  style="border: solid 1px #bdbdbd"
                  class="pt-0 pb-0 pl-0 pr-0"
                >
                  <v-card flat ripple style="border-radius: 0">
                    <template
                      v-if="karte.cooperate_with_clinic && isNotClinicDoctor()"
                    >
                      <div
                        style="padding-left: 14px"
                        class="strong notice pt-4"
                      >
                        <v-icon
                          medium
                          color="rgb(255, 57, 85)"
                          style="margin-top: -2px"
                        >
                          info </v-icon
                        >要共有のカルテです
                      </div>
                    </template>
                    <v-card-text
                      class="pt-1 pb-1"
                      v-text="reservation.description"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <!--// 事前問診情報 -->

          <!-- 関連情報 -->
          <template v-if="recommendJournals.length != 0">
            <v-container class="pa-0">
              <v-row>
                <v-col sm="12" md="12">
                  <recommend-journals ref="recommend-journals" />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!--// 関連情報 -->

          <v-container fluid class="pa-0">
            <!--// 当番医・運営用 -->
            <template v-if="isNotClinicDoctor()">
              <v-row class="pa-0">
                <v-col md="4" class="pt-2 pr-2">
                  <karte-addon
                    v-if="reservation.userId != null"
                    ref="addon"
                    :active="editing"
                    :user-id="reservation.userId"
                    :child-id="reservation.childId"
                  />
                </v-col>
                <v-col md="8" class="pa-2 pl-4 pr-8">
                  <karte-body ref="body" :active="editing" />
                  <v-container class="mt-0 pt-0 white">
                    <v-row>
                      <v-col>
                        <p class="text-md-center pink--text">
                          ※ 運営からのお知らせ ※
                        </p>
                        <p
                          style="white-space: pre-wrap; line-height: 25px"
                          v-html="karteReferenceInfo.content"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <karte-operation-fields :active="editing" />
                </v-col>
              </v-row>
            </template>
            <!--// クリニック用 -->
            <template v-if="isClinicDoctor()">
              <karte-clinic ref="clinic" />
            </template>
          </v-container>
        </div>
      </v-col>
      <v-col
        v-if="!reservation.loading && reservation.date && isNotClinicDoctor() && viewableKarte()"
        md="3"
        class="pl-2"
      >
        <!--// チャット画面 -->
        <chat
          ref="chat"
          :message-disabled="
            !reservation.today || this.reservation.toolId == 'tel'
          "
          :chat-id="parseInt($route.query.reservation_id)"
          :department-type="reservation.department_type"
          log-type="karte"
          :coupon-name="reservation.coupon"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.strong {
  font-weight: bold;
}
.notice {
  color: rgb(255, 57, 85);
}
.karte {
  font-size: 14px;

  .redommend-journals {
    background-color: #fdeef3;
  }
}
.karte-detail {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}
</style>

<script>
import { mapGetters } from "vuex";
import ondutyReservationViewLimitMixin from "../mixins/onduty_reservation_view_limit.js";
import PeriodList from "./shared/PeriodList";
import KarteAddon from "./shared/KarteAddon";
import KarteBody from "./shared/KarteBody";
import KarteClinic from "./shared/KarteClinic";
import RecommendJournals from "./shared/RecommendJournals";
import Chat from "./shared/Chat";
import KarteOperationFields from "./shared/KarteOperationFields";
import SyounikaUserInfo from "./shared/karte/SyounikaUserInfo.vue";
import YouthUserInfo from "./shared/karte/SyounikaYouthUserInfo.vue";
import ObstetricsUserInfo from "./shared/karte/ObstetricsUserInfo.vue";

export default {
  name: "Karte",
  components: {
    PeriodList,
    KarteAddon,
    KarteBody,
    KarteClinic,
    RecommendJournals,
    Chat,
    KarteOperationFields,
    SyounikaUserInfo,
    YouthUserInfo,
    ObstetricsUserInfo
  },
  mixins: [ondutyReservationViewLimitMixin],
  data: () => ({
    editing: false,
    errorMessage: null,
  }),
  computed: {
    ...mapGetters([
      "reservation",
      "karte",
      "currentDoctor",
      "reloadTimeoutId",
      "karteReferenceInfo",
      "karteUpdateResult",
      "recommendJournals",
    ]),
  },
  watch: {
    $route() {
      this.endEditing();
      this.clearData();
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  destroyed() {
    this.clearData();
  },
  methods: {
    clearData: function () {
      this.$store.dispatch("clearKarte");
      this.$store.dispatch("clearReservation");
    },
    fetchData: function () {
      this.$store.dispatch("findReservation", {
        reservationId: this.$route.query.reservation_id,
      });
      if (this.$route.query.reservation_id != null) {
        this.$store.dispatch("findKarte", {
          reservationId: this.$route.query.reservation_id,
        });
        this.$store.dispatch("findKarteReferenceInfo", {
          reservationId: this.$route.query.reservation_id,
        });
        this.$store.dispatch("searchRecommendJournals", {
          reservationId: this.$route.query.reservation_id,
        });
        this.$store.dispatch("findRoom", {
          roomId: this.$route.query.room.split("_")[2],
        });
      }
    },
    postKarte: function () {
      this.$refs.addon.updateUser(this.$refs.addon.user);
      if (this.$refs.addon.child.id != null) {
        this.$refs.addon.updateChild(this.$refs.addon.child);
      }
      this.$refs.body.updateKarte(this.$refs.body.karte).then(() => {
        if (this.karteUpdateResult == false) {
          // 保存エラー
          this.errorMessage =
            "カルテの保存に失敗しました。再読込して確認してください。";
        }
      });
      this.endEditing();
    },
    cancel: function () {
      this.endEditing();
      // 入力データをリセットする
      if (this.$route.query.reservation_id != null) {
        this.$store.dispatch("findKarte", {
          reservationId: this.$route.query.reservation_id,
        });
      }
      this.$refs.addon.findUser();
      this.$refs.addon.findChild();
    },
    startEditing: function () {
      this.$store.dispatch("countClear");
      this.$store.dispatch("clearTimeoutApproaching");
      this.editing = true;
    },
    endEditing: function () {
      this.$store.dispatch("countClear");
      this.$store.dispatch("clearTimeoutApproaching");
      this.editing = false;
    },
    isClinicDoctor: function () {
      // 対象クーポンかどうかはAPIでfilter済
      return this.currentDoctor.type == "Clinic::Doctor";
    },
    isNotClinicDoctor: function () {
      return this.currentDoctor.type != "Clinic::Doctor";
    },
    isNotOperator: function () {
      return this.currentDoctor.type != "Operator::Doctor";
    },
    changeColorByTool: function () {
      switch (this.reservation.toolId) {
        case "line_tel":
          return "#FE5757";
        case "line_video":
          return "#11B621";
        case "tel":
          return "#FFB52B";
        default:
          return "#7F7F7F";
      }
    },
    viewableKarte: function () {
      if (this.reservation.date == null) return false;
      if (this.isNotOperator() && this.outOfDate()) {
        return false;
      } else {
        return true;
      }
    },
    outOfDate: function () {
      let reservationDate = new Date(this.reservation.originalDate);
      return reservationDate < this.viewLimitDateFrom;
    },
  },
};
</script>

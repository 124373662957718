<template>
  <v-container
    fluid
    class="pa-2 daytime-advice-sheet"
  >
    <v-row class="pa-4 pt-0 pb-0">
      <v-col
        cols="3"
        sm="3"
        md="3"
        class="pt-0"
      >
        <daytime-advice-sheet-addon
          v-if="daytimeAdviceSheet.userId != null"
          ref="addon"
          :active="editing"
          :user-id="daytimeAdviceSheet.userId"
        />
      </v-col>
      <v-col
        cols="5"
        sm="5"
        md="5"
        class="pt-0"
      >
        <v-row
          v-if="!editing"
          class="pa-0 align-end flex-column"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pl-0 pt-0 justify-end"
          >
            <v-btn
              color="pink"
              class="font-weight-bold"
              block
              dark
              @click="startEditing()"
            >
              編集する
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="editing"
          class="pa-0"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              outlined
              color="pink"
              block
              @click="cancel()"
            >
              キャンセル
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              color="pink"
              class="font-weight-bold"
              block
              dark
              @click="saveSheet()"
            >
              保存する
            </v-btn>
          </v-col>
        </v-row>
        <daytime-advice-sheet-body
          :editing="editing"
        />
        <v-row>
          <v-col>
            <p class="text-md-center pink--text">
              ※ 運営からのお知らせ ※
            </p>
            <p
              style="white-space: pre-wrap; line-height: 25px"
              v-html="daytimeAdviceReferenceInfo.content"
            />
          </v-col>
        </v-row>
        <daytime-advice-images />
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        class="pa-4 pt-0"
        style="height: 100%;"
      >
        <v-btn
          v-if="!editing && !finished"
          color="blue"
          class="font-weight-bold ml-0"
          block
          dark
          @click="finishAdvice()"
        >
          相談を終える
        </v-btn>
        <!--// チャット画面 -->
        <chat
          v-if="daytimeAdviceSheet.id != null"
          ref="chat"
          :message-disabled="finished"
          :chat-id="$route.params.id"
          :department-type="daytimeAdviceSheet.departmentType"
          log-type="daytimeAdvice"
          :coupon-name="daytimeAdviceSheet.couponName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Chat from "./shared/Chat";
import DaytimeAdviceSheetBody from "./shared/DaytimeAdviceSheetBody";
import DaytimeAdviceSheetAddon from "./shared/DaytimeAdviceSheetAddon";
import DaytimeAdviceImages from "./shared/DaytimeAdviceImages";


export default {
  name: "DaytimeAdviceSheet",
  components: {
    Chat,
    DaytimeAdviceSheetBody,
    DaytimeAdviceSheetAddon,
    DaytimeAdviceImages
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    ...mapGetters([
      "daytimeAdviceSheet",
      "daytimeAdviceReferenceInfo",
    ]),
    finished: function (){
      return this.daytimeAdviceSheet.finished;
    }
  },
  watch: {
    "$route" () {
      this.clearData();
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  destroyed() {
    this.clearData();
  },
  methods: {
    ...mapActions(["updateDaytimeAdviceSheet"]),
    fetchData: function() {
      this.$store.dispatch("clearDaytimeAdviceSheet");

      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$store.dispatch("fetchDaytimeAdviceReferenceInfo", {
        id: this.$route.params.id
      });
    },
    clearData: function(){
      this.$store.dispatch("clearDaytimeAdviceSheet");
    },
    startEditing: function() {
      this.editing = true;
    },
    saveSheet: function() {
      this.$refs.addon.updateUser(this.$refs.addon.user);
      this.updateDaytimeAdviceSheet(this.daytimeAdviceSheet).then(() => {
        // TODO: エラーハンドリング
        this.editing = false;
      });
    },
    endEditing: function() {
      this.editing = false;
    },
    finishAdvice: function() {
      if(confirm("相談を終了します。よろしいですか？")) {
        this.$store.dispatch("finishDaytimeAdviceSheet", {
          id: this.$route.params.id
        }).then(() => {
          this.$router.push({ path: "/daytime_advices/room" });
        });
      }
    },
    cancel: function(){
      this.endEditing();
      // 入力データをリセットする
      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$refs.addon.findUser();
    },
  }
};
</script>

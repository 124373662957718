<template>
  <div v-if="isClinicDoctor" class="mt-6">
    <anytime-consultation-inquiry class="pa-0 px-6" />
    <anytime-consultation-clinic ref="clinic" />
  </div>
  <div v-else class="l-anytime-consultation">
    <div class="l-anytime-consultation__main flex-grow-1">
      <div class="pink--text" v-if="errorMessage != null">
        {{ errorMessage }}
      </div>

      <v-btn :to="{ path: '/anytime_consultations', query: { tab_id: this.tabId } }" text color="pink">
        一覧へ戻る
      </v-btn>

      <!-- 事前問診 -->
      <anytime-consultation-inquiry :class="{'pa-0': true, 'px-6': true, 'is-minimum': isSearchbarMaximum }" />
      <!--/ 事前問診 -->

      <!-- 関連情報 -->
      <template v-if="!isSearchbarMaximum">
        <recommend-journals ref="recommend-journals" />
      </template>
      <!--// 関連情報 -->
      
      <div class="l-anytime-consultation-answer d-flex flex-column flex-sm-row pa-0 pa-sm-6">
        <div :class="{'l-anytime-consultation-answer__left': true, 'w0': isSearchbarMaximum }">
          <!-- サイドバー -->
          <anytime-consultation-addon 
            v-if="consultation.userId != null" 
            ref="addon" 
            :active="answer.editable"
            :user-id="consultation.userId" 
            :child-id="consultation.childId"
          />
          <!--/ サイドバー -->
        </div>
        <div class="l-anytime-consultation-answer__right pa-0 ma-sm-2" v-if="isNotClinicDoctor">
          <div class="pa-6 p-answer-form">
            <div>
              <h3 style="color:#D63864;">
                相談員記入欄
              </h3>
              <div class="pt-2">
                <b>回答状況</b>
                <span class="pr-3">： {{ answer.status }}</span>
              </div>
              <div>
                <b>更新日時</b>
                <span class="pr-3">： {{ answer.updatedAt }}</span>
              </div>
              <div>
                <b>記入者</b>
                <span class="pr-3">： {{ answer.doctorName }}</span>
              </div>
              <div>
                <b>送信日時</b>
                <span class="pr-3">： {{ answer.sentAt }}</span>
              </div>
            </div>
            <!-- 回答記入 -->
            <div class="pt-2">
              <v-textarea 
                v-model="answer.complaint" 
                outlined 
                hide-details 
                name="complaint" 
                label="主訴（必須）*" 
                rows="2"
                :readonly="!answer.editable" 
                :disabled="!answer.editable"
                required
                @focus="isComplaintFocus = true"
                @blur="isComplaintFocus = false"
              />
              <div class="mb-5">
                <div v-if="isComplaintFocus" style="color: #FB0606;">
                  ■非医療者にもわかる言葉で書いてください。<br>
                  ■個人を特定できる情報は入力しないでください（妊娠週数、月齢など）<br>
                  ※自治体・企業への共有、相談事例として公開することがあるため<br>
                </div>
              </div> 
              <midwife-diagnosis v-if="consultation.department_type === 'midwife'" v-model="answer"
                                 :active="answer.editable"
              />
              <diagnosis v-else v-model="answer" :department-data="consultation.department_type"
                         :active="answer.editable"
              />
              <v-textarea 
                v-model="answer.content" 
                outlined 
                name="answer-content" 
                label="回答（必須）*" 
                rows="18"
                :readonly="!answer.editable" 
                :disabled="!answer.editable" 
                required
                class="linefeed-label answer-input" 
                hide-details
              />
              <div>
                <span>{{ this.answerContentLength }}文字</span>
                <span v-if="isEnoughContent">
                  <v-icon color="green" style="font-size: 1em">
                    check_circle
                  </v-icon>
                </span>
                <span v-else>
                  （推奨文字数まで {{ 700 - this.answerContentLength }} 文字）
                </span>
              </div>
              <!-- カルテ情報 相談内容報告欄 -->
              <div class="cooperated-area" v-if="consultation.cooperateWithClinic && answer.forReporting">
                <h3 style="color: #D63864;" class="text-center">
                  相談内容報告欄
                </h3>
                <div>
                  この相談はかかりつけクリニックに報告する必要があります。<br>
                  事前問診のボックス内（氏名・生年月日など）と以下の3つの欄に入力の内容をクリニックの医師が閲覧します。<br>
                  相談内容報告欄の記載をお願いします。
                </div>
                <v-btn 
                  :disabled="!answer.editable" 
                  color="pink lighten-1" 
                  @click="copyFromKarteFields()"
                >
                  カルテ内容を反映する
                  <v-icon right>
                    file_copy
                  </v-icon>
                </v-btn>

                <v-textarea
                  v-model="answer.forReporting.complaint" 
                  outlined
                  hide-details 
                  name="complaint_for_reporting"
                  label="報告用主訴（必須）*" 
                  required
                  rows="2" 
                  :readonly="!answer.editable" 
                  :disabled="!answer.editable"
                  class="mb-4"
                />

                <v-textarea 
                  v-model="answer.forReporting.diagnosis" 
                  outlined 
                  hide-details 
                  name="diagnosis_for_reporting"
                  label="報告用暫定診断（必須）*" 
                  required
                  rows="2" 
                  :readonly="!answer.editable" 
                  :disabled="!answer.editable"
                  class="mb-4"
                />

                <v-textarea 
                  v-model="answer.forReporting.content" 
                  outlined 
                  name="answer-content_for_reporting"
                  label="報告用回答（必須）*" 
                  required
                  rows="18" 
                  counter 
                  :readonly="!answer.editable" 
                  :disabled="!answer.editable"
                  class="linefeed-label"
                />

              </div>
              <!--// カルテ情報 相談内容報告欄 -->

              <div class="p-btn-area d-flex justify-end flex-wrap">
                <template v-if="answer.editable">
                  <v-btn color="green" tile @click="saveDraft()">
                    下書き保存
                  </v-btn>
                  <div class="d-flex ml-8">
                    <div class="requiredTimeSelect">
                      <v-select 
                        v-model="answer.requiredTime"
                        :items="requiredTimeLabels" 
                        required
                        label="回答の作成時間" dense solo filled 
                      />
                    </div>
                    <v-btn class="sendBtn" style="margin-left: 1px!important;" color="pink" tile @click="completeAnswer()">
                      送信予約
                    </v-btn>
                  </div>
                </template>
                <template v-else-if="answer.sentAt == null">
                  <div class="requiredTimeSelect">
                    <v-select v-model="answer.requiredTime" :items="requiredTimeLabels" label="回答の作成時間" dense solo filled disabled hide-details />
                  </div>
                  <v-btn
                    color="pink lighten-1" style="margin-left: 1px!important;"
                    tile
                    outlined
                    @click="cancelAnswer()"
                  >
                    送信予約取り消し
                  </v-btn>
                </template>
                <template v-else>
                  <div class="requiredTimeSelect">
                    <v-select v-model="answer.requiredTime" :items="requiredTimeLabels" label="回答の作成時間" dense solo filled disabled hide-details />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- お知らせ欄 -->
          <div class="mt-8">
            <p class="text-md-center pink--text">
              ※ 運営からのお知らせ ※
            </p>
            <div class="px-6">
              <p 
                style="white-space: pre-wrap; line-height: 25px; word-break: break-all;" 
                v-html="referenceInfo.content" 
              />
            </div>
          </div>
          <!--/ お知らせ欄 -->
          <!-- 内容クリア -->
          <div class="text-center" v-if="!!answer.id && answer.editable">
            <v-btn
              color="pink"
              style="text-decoration: underline;"
              text
              @click="deleteAnswer()"
            >
              このカルテの内容をクリアする
            </v-btn>
          </div>
          <!--/ 内容クリア -->
        </div>
      </div>
    </div>
    <anytime-consultation-search-bar 
      v-if="answer.editable"
      class="l-anytime-consultation__searchbar flex-grow-0 d-none d-sm-flex" 
      :width-rank="widthRank"
      @updateWidthRank="updateWidthRank"
    />
  </div>
</template>

<style lang="scss">

.l-anytime-consultation {
  display: flex;
  overflow: auto;
  height: 100vh !important;
  width: 100%;
  font-size: 14px;

  &__main{
    @media (min-width: 600px) {
      max-height: 100vh;
      overflow: scroll;
    }
  }
  // &__searchbar{}
}

.l-anytime-consultation-answer{
  &__left{
    width: 360px;
    transition: width 0.3s 0s ease;
    @media (max-width: 600px) {
      width: 100%;
    } 
    &.w0{
      width: 0px;
    }
  }
  &__right{
    width: 100%;
  }

  .v-text-field--outlined:not(.v-input--is-focused) fieldset,
  .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover fieldset {
    border: 2px solid rgba(0, 0, 0, .54);
  }

  // 入力欄の背景を薄青色にする
  .v-input.v-text-field:not(.v-input--is-disabled) fieldset {
    background-color: #e8f2ff !important;
  }
  .parent-special-note .v-input.v-text-field:not(.v-input--is-disabled) {
    background-color: #e8f2ff !important;
  }
  .child-special-note .v-input.v-text-field:not(.v-input--is-disabled) {
    background-color: #e8f2ff !important;
  }
}

.p-answer-form {
  background-color: #fdeef3;

  .cooperated-area {
    border-top: 1px solid #D63864;
    padding-top: 16px;
    margin-top: 16px;
  }
}

.p-btn-area {
  .requiredTimeSelect {
    max-width: 200px;

    .v-input__slot {
      min-height: 36px !important;
      margin-top: 6px;
      background: #EF5A8D !important;
      border-radius: 0px !important;
      color: #fff !important;
    }

    .theme--light.v-icon {
      font-size: 14px;
      color: #fff !important;
    }

    .theme--light.v-label {
      font-size: 14px;
      color: #fff !important;
    }

    .v-select__selection--comma {
      font-size: 14px;
      color: #fff;
    }

    .mdi-menu-down::before{
      font-size: 2em
    }
  }
  .sendBtn {
    .v-btn__content {
      color: #fff;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Diagnosis from "./shared/Diagnosis";
import MidwifeDiagnosis from "./shared/MidwifeDiagnosis";
import RecommendJournals from "./shared/RecommendJournals";
import AnytimeConsultationInquiry from "./shared/AnytimeConsultationInquiry";
import AnytimeConsultationAddon from "./shared/AnytimeConsultationAddon";
import AnytimeConsultationClinic from "./shared/AnytimeConsultationClinic";
import AnytimeConsultationSearchBar from "./shared/AnytimeConsultationSearchBar";

export default {
  name: "AnytimeConsultation",
  components: {
    Diagnosis,
    MidwifeDiagnosis,
    RecommendJournals,
    AnytimeConsultationInquiry,
    AnytimeConsultationAddon,
    AnytimeConsultationClinic,
    AnytimeConsultationSearchBar
  },
  data: () => ({
    tabId: 0,
    errorMessage: null,
    widthRank: 2,
    isComplaintFocus: false,
    requiredTimeLabels: [
      { text: "回答の作成時間", value: null },
      { text: "5分未満", value: "less_5" },
      { text: "5分以上10分未満",  value: "between_5_10" },
      { text: "10分以上20分未満", value: "between_10_20" },
      { text: "20分以上30分未満", value: "between_20_30" },
      { text: "30分以上40分未満", value: "between_30_40" },
      { text: "40分以上50分未満", value: "between_40_50" },
      { text: "50分以上60分未満", value: "between_50_60" },
      { text: "60分以上", value: "more_60" },
    ],
  }),
  computed: {
    ...mapGetters({
      consultation: "anytimeConsultation",
      currentDoctor: "currentDoctor",
      answer: "anytimeConsultationAnswer",
      cancelResult: "anytimeConsultationUpdateResult",
      referenceInfo: "anytimeConsultationReferenceInfo",
      recommendJournals: "recommendJournals"
    }),
    isClinicDoctor() {
      if (this.currentDoctor === null){
        return false
      }
      return this.currentDoctor.type == "Clinic::Doctor";
    },
    isNotClinicDoctor: function () {
      if (this.currentDoctor === null){
        return false
      }
      return this.currentDoctor.type != "Clinic::Doctor";
    },
    isFilledReportingFields: function() {
      if (this.answer === null || this.answer.forReporting === null || typeof this.answer.forReporting === "undefined" ){
        return false
      }
      return (this.answer.forReporting.content != null && this.answer.forReporting.content != "")
                || (this.answer.forReporting.complaint != null  && this.answer.forReporting.complaint != "")
                || (this.answer.forReporting.diagnosis != null   && this.answer.forReporting.diagnosis != "")
    },
    isCompletedReportingFields: function() {
      if (this.answer === null || this.answer.forReporting === null || typeof this.answer.forReporting === "undefined" ){
        return false
      }
      return (this.answer.forReporting.content != null && this.answer.forReporting.content != "")
                && (this.answer.forReporting.complaint != null  && this.answer.forReporting.complaint != "")
                && (this.answer.forReporting.diagnosis != null   && this.answer.forReporting.diagnosis != "")
    },
    isAnswerContentFilled: function() {
      return !!this.answer.complaint && (!!this.answer.branch_id || !!this.answer.original_disease) && !!this.answer.content
    },
    isRequiredTimeFilled: function() {
      return !!this.answer.requiredTime
    },
    isContainedDraftMessage: function() {
      return this.answer.content.match(/「ーーーーーーーーーーーー」/) || this.answer.content.match(/（回答本文）/)
    },
    answerContentLength: function () {
      if (this.answer === null || this.answer.content === null || typeof this.answer.content === "undefined") {
        return 0
      }
      return this.answer.content.length
    },
    isEnoughContent: function () {
      return this.answerContentLength >= 700
    },
    isSearchbarMaximum: function () {
      return this.widthRank == 3
    }
  },
  created() {
    // 一旦モデルを空にする
    this.clearEpdsAnswers();
    this.$store.dispatch("clearAnytimeConsultationObjects");

    this.$store.dispatch("findAnytimeConsultation", {
      id: this.$route.params.id
    });
    this.$store.dispatch("findAnytimeConsultationAnswer", {
      anytime_consultation_id: this.$route.params.id
    });
    this.$store.dispatch("findAnytimeConsultationReferenceInfo", {
      anytime_consultation_id: this.$route.params.id
    });
    this.$store.dispatch("searchRecommendJournals", {
      anytimeConsultationId: this.$route.params.id
    });
    this.tabId = this.$route.params.tab_id;
  },
  methods: {
    ...mapActions([
      "updateUser",
      "updateChild",
      "clearEpdsAnswers"
    ]),
    saveDraft: function() {
      this.$store.dispatch("saveAnytimeConsultationAnswerDraft", {
        anytime_consultation_id: this.consultation.id,
        content: this.answer.content,
        complaint: this.answer.complaint,
        icd10_branch_id: this.answer.branch_id,
        icd10_original_disease: this.answer.original_disease,
        content_for_reporting: this.answer.forReporting.content,
        complaint_for_reporting: this.answer.forReporting.complaint,
        diagnosis_for_reporting: this.answer.forReporting.diagnosis,
        required_time: this.answer.requiredTime
      });
      this.updateUser(this.$refs.addon.user);
      this.updateChild(this.$refs.addon.child);
    },
    completeAnswer: function() {
      if(!this.isAnswerContentFilled){
        return alert("記入欄に入力漏れがあります");
      }
      if(!this.isRequiredTimeFilled){
        return alert("「回答の作成時間」を選択してください");
      }
      if(this.consultation.cooperateWithClinic && this.answer.forReporting && !this.isCompletedReportingFields){
        return alert("報告欄に入力漏れがあります");
      }
      if(this.isContainedDraftMessage){
        return alert("回答文にテンプレート文章が残っています");
      }

      this.$store.dispatch("completeAnytimeConsultationAnswer", {
        anytime_consultation_id: this.consultation.id,
        content: this.answer.content,
        complaint: this.answer.complaint,
        icd10_branch_id: this.answer.branch_id,
        icd10_original_disease: this.answer.original_disease,
        content_for_reporting: this.answer.forReporting.content,
        complaint_for_reporting: this.answer.forReporting.complaint,
        diagnosis_for_reporting: this.answer.forReporting.diagnosis,
        required_time: this.answer.requiredTime
      });
      this.$store.dispatch("findAnytimeConsultationReferenceInfo", {
        anytime_consultation_id: this.$route.params.id
      });
      this.tabId = this.$route.params.tab_id;
      this.updateUser(this.$refs.addon.user);
      this.updateChild(this.$refs.addon.child);
    },
    cancelAnswer: function() {
      this.$store.dispatch("cancelAnytimeConsultationAnswer", {
        anytime_consultation_id: this.consultation.id,
      }).then(() => {
        if(this.cancelResult == false){
          this.errorMessage = "送信済みのため取り消しできませんでした";
          // 最新を再取得
          this.$store.dispatch("findAnytimeConsultationAnswer", {
            anytime_consultation_id: this.$route.params.id
          });
        }
      });
    },
    deleteAnswer: function() {
      if(confirm("入力された内容を全てクリアします。本当によろしいですか？")){
        this.$store.dispatch("deleteAnytimeConsultationAnswer", {
          anytime_consultation_id: this.consultation.id
        })
      }
    },
    copyFromKarteFields: function () {
      if(this.isFilledReportingFields) {
        if(confirm("入力済みの報告が消えますが本当にコピーしますか？")) {
          this.copyFields();
        }
      } else {
        this.copyFields();
      }
    },
    copyFields: function() {
      this.answer.forReporting.content = this.answer.content;
      this.answer.forReporting.complaint = this.answer.complaint;
      this.answer.forReporting.diagnosis = this.answer.icd10BranchName;
    },
    updateWidthRank: function (widthRank) {
      this.widthRank = widthRank
    }
  }
};
</script>

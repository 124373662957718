<template>
  <v-row>
    <v-col
      md="12"
      class="pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>関連するいつでもカルテ一覧（他科・兄弟など）</v-toolbar-title>
        </v-toolbar>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col
              class="pt-0 pb-0"
              style="overflow-y: auto; max-height: 400px;"
            >
              <v-card
                v-for="consultation in anytimeConsultations"
                :key="consultation.id"
              >
                <v-card-text>
                  <div>
                    <p class="mb-1">
                      {{ consultation.title }}
                    </p>
                    <p class="mb-1 d-flex">
                      {{ consultation.simpleAskedAt }} （{{ consultation.doctorName }}）
                    </p>
                    <child-property
                      v-if="consultation.departmentType === 'pediatrician'"
                      :object="consultation"
                      :is-child-name-displayable="false"
                    />
                    <p class="mb-1">
                      回答状況：{{ consultation.status }}
                    </p>
                    <p class="mb-1">
                      ICD10暫定診断：{{ consultation.icd10BranchName }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <a @click="openAnytimeConsultationView(consultation.id)">
                    <v-btn
                      small
                      color="pink lighten-1"
                    >
                      閲覧
                      <v-icon right>visibility</v-icon>
                    </v-btn>
                  </a>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #FFF;
  font-size: 14px;
}
</style>

<script>
import ChildProperty from "./ChildProperty.vue";

export default {
  name: "AnytimeConsultationKarteList",
  components: {
    ChildProperty
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ""
    },
    anytimeConsultations: {
      type: Array,
      default: null
    },
    isChildNameDisplayable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    openAnytimeConsultationView: (id) => {
      window.open("/anytime_consultations/view/" + id, "", "width=480, height=640");
    },
  }
};
</script>

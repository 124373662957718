<template>
  <v-container
    fluid
    class="monitor pa-8"
  >
    <v-row
      class="pa-2"
    >
      <v-col
        cols="10"
        sm="10"
        md="10"
        class="pa-0 pl-2"
      >
        <h4>本日の担当相談員</h4>
      </v-col>
      <v-col
        cols="2"
        sm="2"
        md="2"
        class="pa-0 pr-2 text-right"
      >
        <h4 class="pink--text">
          現在のプーリング: {{ poolingCount }}件
        </h4>
      </v-col>
    </v-row>
    <v-row
      class="pa-2"
    >
      <v-expansion-panels accordion>
        <v-expansion-panel
          v-for="doctor in activeDoctorsWithSheets"
        >
          <v-expansion-panel-header>
            <v-row
              justify="center"
              class="pa-0"
            >
              <v-col
                cols="2"
                sm="2"
                md="2"
                class="text-center"
              >
                <v-avatar
                  tile
                  left
                  size="80"
                >
                  <img
                    :src="doctor.iconUrl"
                  >
                </v-avatar>
                <div class="pt-2">
                  {{ doctor.name }}
                  <v-icon
                    small
                    :color="doctor.isActive ? 'blue' : 'grey lighten-2'"
                  >
                    mdi-chat-processing
                  </v-icon>
                </div>
              </v-col>
              <v-col
                cols="10"
                sm="10"
                md="10"
              >
                <div class="pa-2">
                  <span class="status-label">未対応</span>: {{ doctor.notStartedYetCount }}件
                </div>
                <div class="pa-2">
                  <span class="status-label">保留中</span>: {{ doctor.inProcessCount }}件
                </div>
                <div class="pa-2">
                  <span class="status-label">終了</span>: {{ doctor.doneCount }}件
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <router-link
              v-for="sheet in doctor.daytimeAdviceSheets"
              :key="sheet.id"
              :to="{ name: 'daytimeAdviceSheet', params: { id: sheet.id }}"
              class="d-flex xs12 ma-1 card--hover assigned-sheet"
              style="border-radius: 2px"
            >
              <v-card
                tile
                height="100%"
                width="100%"
              >
                <v-card-text
                  class="pa-0"
                >
                  <v-row
                    justify="center"
                    class="pl-4"
                  >
                    <v-col
                      cols="1"
                      sm="1"
                      md="1"
                      class="text-center"
                    >
                      <v-btn
                        small
                        :color="labelColor(sheet.status)"
                        class="ml-0"
                        max-width="contents"
                        dark
                        tile
                        depressed
                      >
                        {{ sheet.statusI18n }}
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="11"
                      sm="11"
                      md="11"
                    >
                      <div><b>相談者:</b> {{ sheet.userName }}(ID: {{ sheet.userId }}) | <b>対応時間:</b> {{ sheet.startAt }}{{ sheet.endAt }}</div>
                      <div><b>主訴:</b> {{ sheet.complaint }}</div>
                      <div><b>暫定診断:</b> {{ sheet.icd10BranchName }}</div>
                      <div><b>転帰:</b> {{ sheet.outcomeI18n }}</div>
                      <div
                        v-if="sheet.noAdvice"
                      >
                        <b>※ 相談なし</b>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import commonMixin from "../mixins/daytime_advice_common.js";

export default {
  name: "DaytimeAdviceMonitor",
  mixins: [commonMixin],
  data: () => ({
  }),
  computed: {
    ...mapGetters([
      "activeDoctorsWithSheets",
      "poolingCount",
      "daytimeAdviceTimeoutId",
    ]),
  },
  watch: {
    "$route" () {
      this.startAutoReload();
    }
  },
  created() {
    this.startAutoReload();
  },
  destroyed() {
    // 自動更新を停止する
    clearInterval(this.daytimeAdviceTimeoutId);
  },
  methods: {
    fetchData: function() {
      this.$store.dispatch("fetchActiveDoctorsWithSheets");
      this.$store.dispatch("fetchPoolingCount");
    },
    startAutoReload: function() {
      // 以前設定されたtimeoutがあれば念の為クリア
      clearInterval(this.daytimeAdviceTimeoutId);
      // 最初の1回の実行
      this.fetchData();
      // 30秒
      var timeoutId = setInterval(this.fetchData, 30 * 1000);
      this.$store.dispatch("assignDaytimeAdviceMonitorTimeoutId", timeoutId);
    }
  },
};
</script>
<style lang="scss" scoped>
.monitor {
  background-color: rgb(253, 238, 243);
}
.search-condition {
  background-color: #fff;
}
.assigned-sheet {
  text-decoration: none;
  .v-card__text {
    font-size: 12px;
  }
}
.status-label {
  width: 50px;
  display: inline-block;
}
</style>

<template>
  <v-content>
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          sm8
          md4
        >
          <v-card class="elevation-12">
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="email"
                  prepend-icon="person"
                  name="email"
                  label="メールアドレス"
                  type="text"
                />
                <v-text-field
                  v-model="password"
                  prepend-icon="lock"
                  name="password"
                  label="パスワード"
                  type="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer>
                <span
                  v-if="message != ''"
                  class="notice login-failure"
                >{{ message }}</span>
              </v-spacer>
              <v-btn
                color="pink"
                @click="onLogin()"
              >
                ログイン
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<style>
.notice {
  color: rgb(233, 30, 99);
  font-size: .875rem;
}
.login-failure {
  padding-left: 12px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    email: "",
    password: "",
    redirectPath: "",
    message: ""
  }),
  computed: {
    ...mapGetters(["loggedIn"]),
  },
  methods: {
    ...mapActions(["login"]),
    onLogin: function() {
      this.login({ email: this.email,
        password: this.password,
        router: this.$router,
        redirectPath: this.redirectPath }
      ).then(() => {
        if(this.loggedIn == false){
          this.message = "ログインに失敗しました";
        } else {
          this.$emit("setUpNotice");
          this.$emit("setUpLogoutTimer");
        }
      });
    },
  },
  created() {
    this.redirectPath = this.$route.query.redirect_to;
  }
};
</script>

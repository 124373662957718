<template>
  <v-container
    fluid
    class="pa-0 top-menu"
  >
    <v-app-bar
      clipped-left
      color="grey lighten-4"
      class="pink--text"
    >
      <v-row
        class="pa-2"
        align-content="center"
      >
        <v-col
          align-self="center"
          sm="5"
          md="2"
          class="hidden-xs-only"
        >
          <v-spacer />
          <v-toolbar-title md8>
            <router-link
              tag="span"
              :to="{ path: '/reservations/list'}"
            >
              KP相談カルテ
            </router-link>
          </v-toolbar-title>
        </v-col>
        <v-col
          align-self="center"
          cols="11"
          sm="6"
          md="3"
        >
          <div style="height:50px">
            <v-menu
              v-if="hasSearchListener"
              bottom
              offset-y
              min-width="auto"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  readonly
                  solo
                  color="pink"
                  placeholder="日付"
                  prepend-inner-icon="event"
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                color="pink"
                first-day-of-week="1"
                locale="ja"
                :min="isOperator ? null : formattedViewLimitDateFrom"
                @input="$emit('search', date)"
              />
            </v-menu>
          </div>
        </v-col>
        <v-col
          align-self="center"
          class="pa-0"
          cols="1"
          sm="1"
          md="7"
        >
          <!-- 折りたたまないメニュー -->
          <v-toolbar-items
            class="justify-end hidden-sm-and-down"
          >
            <v-btn
              v-if="nightConsultationAvailable"
              :to="{ name: 'list', query: { date: $route.query.date }}"
              text
              color="pink"
              class="pa-0"
            >
              夜間相談
            </v-btn>
            <v-btn
              v-if="isNotClinicDoctor && anytimeConsultationAvailable"
              :to="{ path: '/anytime_consultations', query: { tab_id: '0', department: 1, complete: 0 } }"
              text
              color="pink"
              class="pa-0"
            >
              いつでも相談
            </v-btn>
            <v-btn
              v-if="isNotClinicDoctor && daytimeAdviceAvailable"
              :to="{ name: 'daytimeAdviceRoom' }"
              text
              color="pink"
              class="pa-0"
            >
              日中助産師
            </v-btn>
            <v-btn
              v-if="isOperator"
              :to="{ name: 'shift', query: {date: $route.query.date}}"
              text
              color="pink"
              class="pa-0"
            >
              夜間シフト
            </v-btn>
            <v-btn
              v-if="isOperator"
              :to="{ name: 'ac_shift', query: {date: $route.query.date}}"
              text
              color="pink"
              class="pa-0"
            >
              いつでもシフト
            </v-btn>
            <!-- <v-btn text color="pink">アカウント</v-btn> -->
            <v-btn
              text
              color="pink"
              class="pa-0"
              @click="$emit('logout')"
            >
              ログアウト
            </v-btn>
          </v-toolbar-items>
          <!-- 折りたたみメニュー表示ボタン -->
          <v-app-bar-nav-icon
            class="hidden-md-and-up"
            @click="drawer = true"
          />
        </v-col>
      </v-row>
    </v-app-bar>
    <!-- 折りたたみメニュー -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      right
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          class="text--accent-4"
          active-class="font-weight-bold"
        >
          <v-list-item
            v-if="nightConsultationAvailable"
            link
            :to="{ name: 'list', query: { date: $route.query.date }}"
          >
            <v-list-item-title class="pink--text">
              夜間相談
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isNotClinicDoctor && anytimeConsultationAvailable"
            link
            :to="{ path: '/anytime_consultations', query: { tab_id: '0' } }"
          >
            <v-list-item-title class="pink--text">
              いつでも相談
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isNotClinicDoctor && daytimeAdviceAvailable"
            link
            :to="{ name: 'daytimeAdviceRoom' }"
          >
            <v-list-item-title class="pink--text">
              日中助産師
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isOperator"
            link
            :to="{ name: 'shift', query: {date: $route.query.date}}"
          >
            <v-list-item-title class="pink--text">
              夜間シフト
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isOperator"
            link
            :to="{ name: 'ac_shift', query: {date: $route.query.date}}"
          >
            <v-list-item-title class="pink--text">
              いつでもシフト
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="$emit('logout')"
          >
            <v-list-item-title class="pink--text">
              ログアウト
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ondutyReservationViewLimitMixin from "../../mixins/onduty_reservation_view_limit.js";

export default {
  name: "TopMenu",
  mixins: [ondutyReservationViewLimitMixin],
  data: () => ({
    date: null,
    drawer: false
  }),
  computed: {
    ...mapGetters(["currentDoctor"]),
    formattedViewLimitDateFrom : function (){
      return this.formatDate(this.viewLimitDateFrom);
    },
    today: function() {
      return this.formatDate(new Date());
    },
    hasSearchListener: function() {
      return this.$listeners && this.$listeners.search;
    },
    isOperator: function(){
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
    isNotClinicDoctor: function() {
      return this.currentDoctor && this.currentDoctor.type != "Clinic::Doctor";
    },
    nightConsultationAvailable: function() {
      return this.currentDoctor && this.currentDoctor.nightConsultationAvailable;
    },
    anytimeConsultationAvailable: function() {
      return this.currentDoctor && this.currentDoctor.anytimeConsultationAvailable;
    },
    daytimeAdviceAvailable: function() {
      return this.currentDoctor && this.currentDoctor.daytimeAdviceAvailable;
    },
  },
  watch: {
    "$route" () {
      this.date = this.$route.query.date ? this.$route.query.date : this.today;
    },
  },
  created() {
    this.date = this.$route.query.date ? this.$route.query.date : this.today;
  },
  methods: {
    formatDate(date){
      return ("0000" + date.getFullYear()).slice(-4) + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + (date.getDate())).slice(-2);
    }
  }
};
</script>

<style lang="scss">
.top-tab {
  .theme--light.v-tabs-bar {
    background-color: rgba(100, 100, 100, 0.2) !important;
  }
}
</style>
